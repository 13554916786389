import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-basket-modal',
  templateUrl: './basket-modal.component.html',
  styleUrls: ['./basket-modal.component.css'],
})
export class BasketModalComponent {
  @Output() updateOrders = new EventEmitter<any[]>();
  myOrders: any[] = [];
  cost = 0;
  total = 0;
  checkoutLink = false
  constructor(
    private authService: AuthService,
    public settingService: SettingsService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<BasketModalComponent>,
    private router:Router
  ) {
    this.checkoutLink = this.router.url.includes('checkout')
  }
  ngOnInit(): void {
    this.loadOrders();
    this.getDeliveryCost();
    console.log("this.router.url")
    console.log()
  }

  loadOrders(): void {
    this.myOrders = JSON.parse(localStorage.getItem('my_orders') || '[]');
  }
  makeOrderBtn() {
    if (
      this.myOrders[0].minOrderPrice >
      this.myOrders[0].deliverFees + this.getTotal()
    ) {
      // this.toast.error(this.translateService.instant('Please do not play with the buttons because I ban you'));
      this.toastr.error(
        this.translateService.instant('MinOrder') +
          this.myOrders[0].minOrderPrice
      );
      return;
    }
    // if (!localStorage.getItem('address')) {
    //   this.toast.warning('Please select address for complete checkout');
    //   localStorage.setItem('comId', this.id.toString());
    //   this.router.navigate(['/']);
    // }
    this.dialogRef.close(false);
    this.router.navigate(['/checkout'], { queryParams: { id: this.myOrders[0].branchId } });
  }
  removeOrder(index: number): void {
    this.myOrders.splice(index, 1);
    localStorage.setItem('my_orders', JSON.stringify(this.myOrders));
    // Pass the updated orders to the navbar component
    this.updateOrders.emit(this.myOrders);
  }

  clearOrders(): void {
    localStorage.removeItem('my_orders');
    this.myOrders = [];
    this.updateOrders.emit(this.myOrders);
  }
  getDeliveryCost() {
    const myOrders = JSON.parse(localStorage.getItem('my_orders') || '[]');
    if (myOrders.length > 0) {
      const branchId = myOrders[0].branchId;
      const latlng =
        localStorage.getItem('lat') + ',' + localStorage.getItem('lng');

      if (localStorage.getItem('lat') && localStorage.getItem('lng')) {
        this.authService.getDeliveryCost(branchId, latlng).subscribe((res) => {
          this.cost = res.data;
          console.log(this.cost);
          localStorage.setItem('deliveryCost', this.cost + '');
          this.updateTotalWithCost();
        });
      }
    } else {
      console.error('No orders found in local storage.');
    }
  }
  updateTotalWithCost() {
    this.total = this.getTotal() + this.cost;
  }
  getTotal(): number {
    return this.myOrders.reduce((total, order) => total + order.total, 0);
  }
}
