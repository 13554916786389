// import { MapsAPILoader } from '@agm/core';
import { MapsAPILoader } from '@agm/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';
import { ChangeAddressModalComponent } from 'src/app/layouts/change-address-modal/change-address-modal.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import {
  InterceptorSkipHeader,
  generateNumberArray,
} from 'src/app/shared/constants/global-variable.model';
declare let $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  addressId = +localStorage.getItem('addressId');
  headers = new HttpHeaders().set(InterceptorSkipHeader, '');
  myAddresses: any[] = [];
  year = new Date().getFullYear();
  getMyAddresses() {
    if (this.authService.checkUserIsLogedIn())
      this._authService.getAddresses(this.headers).subscribe((res) => {
        this.myAddresses = res.data;
      });
  }
  modalRef: NgbModalRef;
  latitude: number = 40.7128; // Default latitude
  longitude: number = -74.006; // Default longitude

  @ViewChild('mapContainer') gmap: ElementRef;
  @ViewChild('modalSelectAddress', { static: false })
  modalTemplate: TemplateRef<any>;

  @ViewChild('selectCountry', { static: false })
  modalTemplateCountry: TemplateRef<any>;
  closeModal() {
    this.modalRef.close();
  }
  openAddressModal() {
    this.i = 1;
    if (!localStorage.getItem('countryId')) {
      this.toastr.warning(
        this.translateService.instant('Please select country first')
      );
      return;
    }
    if (localStorage.getItem('lat')) {
      this.lat = +localStorage.getItem('lat');
      this.newLat = +localStorage.getItem('lat');
    }
    if (localStorage.getItem('lng')) {
      this.lng = +localStorage.getItem('lng');
      this.newLng = +localStorage.getItem('lng');
    }
    this.modalRef = this.modalService.open(this.modalTemplate, {
      centered: true,
      // windowClass: 'modal-large-custom',
      windowClass: 'custom-modal', // Apply custom class
      ariaLabelledBy: 'modal-basic-title',
    });
    this.options2 = {
      componentRestrictions: {
        country: localStorage.getItem('countryCode'),
      },
    };
    this.autoComplete2.setOptions({
      componentRestrictions: { country: localStorage.getItem('countryCode') },
    });
    // this.loadGoogleMaps()
    setTimeout(() => {
      this.geocoder = new google.maps.Geocoder();

      this.loadGoogleMaps();
    }, 200); // Adjust delay as needed
  }

  // onMapClick(event: any) {
  //   // console.log(event)
  //   // this.latitude = event.coords.lat;
  //   // this.longitude = event.coords.lng;
  //   this.mapZoom = 15; // Adjust zoom level on map click

  //   this.lat = event.coords.lat;
  //   this.lng = event.coords.lat;
  //   console.log('event.coords.lat');
  //   // console.log(event)
  //   console.log(event.coords.lat);
  //   console.log(event.coords.lng);
  //   // this.reverseGeocode(this.lat, this.lng);
  //   this.getAddress();
  // }
  // selectAddressFromMap() {
  //   console.log('Selected address:', {
  //     latitude: this.lat,
  //     longitude: this.lng,
  //   });
  //   this.closeModal();
  // }
  // mapReady(event: any) {
  //   // console.log("event")
  //   // console.log(event)
  // }
  // addressFromMap: string = '';
  // reverseGeocode(lat: number, lng: number) {
  //   const geocoder = new google.maps.Geocoder();
  //   const latlng = { lat, lng };

  //   // geocoder.geocode({ location: latlng }, (results, status) => {
  //   //   if (status === google.maps.GeocoderStatus.OK) {
  //   //     console.log(results)
  //   //     if (results[0]) {
  //   //       this.address = results[0].formatted_address;
  //   //       console.log('Selected address:', this.address);
  //   //     } else {
  //   //       console.log('No results found');
  //   //     }
  //   //   } else {
  //   //     console.log('Geocoder failed due to: ' + status);
  //   //   }
  //   // });
  // }
  // mapZoom: number = 12; // Default zoom level

  searchAddressMap = '';
  // searchLocation() {
  //   if (this.searchAddressMap.trim() !== '') {
  //     this.mapsAPILoader.load().then(() => {
  //       let geocoder = new google.maps.Geocoder();

  //       geocoder.geocode(
  //         { address: this.searchAddressMap },
  //         (results, status) => {
  //           if (
  //             status === google.maps.GeocoderStatus.OK &&
  //             results.length > 0
  //           ) {
  //             this.lat = results[0].geometry.location.lat();
  //             this.lng = results[0].geometry.location.lng();
  //             console.log(this.lat);
  //           }
  //         }
  //       );
  //     });
  //   }
  // }
  searchLocation() {
    // console.log(event.target.value);
    this.geocoder.geocode(
      { address: this.searchAddressMap },
      (results: any, status: any) => {
        console.log(results);
        console.log(status);
        if (status === 'OK') {
          this.map.setCenter(results[0].geometry.location);
          new google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location,
          });
        }
      }
    );
  }
  getAddress() {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(this.lat, this.lng);
      let geocoderRequest: google.maps.GeocoderRequest = {
        location: latlng,
      };

      geocoder.geocode(geocoderRequest, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          this.address = results[0].formatted_address;
          console.log(this.address);
        } else {
          console.error('Error in geocoding:', status);
          // Log detailed error message
          console.error(results);
        }
      });
    });
  }
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  searchAddress: string = '';
  marker: google.maps.Marker;
  currentLocationMarker: google.maps.Marker | null = null; // Initialize marker for current location
  truncateAddress(address: string, maxLength: number): string {
    if (address.length > maxLength) {
      return address.substring(0, maxLength) + '...'; // Add ellipsis if truncated
    }
    return address;
  }
  i = 0;
  getCurrentLocation(type = 0) {
    if (type == 1) this.i = 0;
    console.log(navigator.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        // Create a new instance of Geocoder
        const geocoder = new google.maps.Geocoder();

        // Perform reverse geocoding
        geocoder.geocode({ location: pos }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              // Get the address from the results
              if (this.i == 1) return;
              this.address = this.truncateAddress(
                results[0].formatted_address,
                50
              );
              localStorage.setItem('address', this.address);
              localStorage.setItem('lng', this.lng.toString());
              localStorage.setItem('lat', this.lat.toString());
              // Extracting additional address components
              const addressComponents = results[0].address_components;
              const addressInfo = {
                streetNumber: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                postalCode: '',
                buildingNumber: '',
                apartmentNumber: '',
                region: '',
              };
              console.log(addressComponents);
              addressComponents.forEach((component) => {
                const types = component.types;

                if (types.includes('street_number')) {
                  addressInfo.streetNumber = component.long_name;
                } else if (types.includes('route')) {
                  addressInfo.streetName = component.long_name;
                } else if (types.includes('locality')) {
                  addressInfo.city = component.long_name;
                } else if (types.includes('administrative_area_level_1')) {
                  addressInfo.state = component.long_name;
                } else if (types.includes('country')) {
                  addressInfo.country = component.long_name;
                } else if (types.includes('postal_code')) {
                  addressInfo.postalCode = component.long_name;
                } else if (
                  types.includes('sublocality') ||
                  types.includes('sublocality_level_1')
                ) {
                  addressInfo.region = component.long_name;
                } else if (types.includes('premise')) {
                  addressInfo.buildingNumber = component.long_name;
                } else if (types.includes('subpremise')) {
                  addressInfo.apartmentNumber = component.long_name;
                }
              });

              // Logging the extracted address components
              console.log('Street Number:', addressInfo.streetNumber);
              console.log('Street Name:', addressInfo.streetName);
              console.log('City:', addressInfo.city);
              console.log('State:', addressInfo.state);
              console.log('Country:', addressInfo.country);
              console.log('Postal Code:', addressInfo.postalCode);

              // Storing additional address components in local storage
              localStorage.setItem('streetNumber', addressInfo.streetNumber);
              localStorage.setItem('streetName', addressInfo.streetName);
              localStorage.setItem('city', addressInfo.city);
              localStorage.setItem('state', addressInfo.state);
              localStorage.setItem('country', addressInfo.country);
              localStorage.setItem('postalCode', addressInfo.postalCode);
              localStorage.setItem(
                'buildingNumber',
                addressInfo.buildingNumber
              );
              localStorage.setItem(
                'apartmentNumber',
                addressInfo.apartmentNumber
              );
              localStorage.setItem('region', addressInfo.region);
              const addressData = {
                apartmentNo: addressInfo.apartmentNumber,
                buildingNo: addressInfo.buildingNumber,
                city: addressInfo.city,
                country: addressInfo.country,
                latLng: `${this.lat},${this.lng}`,
                street: `${addressInfo.streetNumber} ${addressInfo.streetName}`,
                region: addressInfo.region,
                phoneNumber: '', // Add phoneNumber if available
                saveAddressAs: '', // Add saveAddressAs if needed
                isSaveAddress: true,
              };
              localStorage.setItem('addressData', JSON.stringify(addressData));
              // if (type == 1) this.modalService.dismissAll();
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to:', status);
          }
        });

        if (this.currentLocationMarker) {
          // If the marker for the current location exists, remove it from the map
          this.currentLocationMarker.setMap(null);
          this.currentLocationMarker = null; // Reset the currentLocationMarker reference
        }
        this.currentLocationMarker = new google.maps.Marker({
          position: pos,
          map: this.map,
          title: 'Your Location',
        });
        // if (type == 1) {
        //   console.log(type);
        //   setTimeout(() => {
        //     this.updateNewLocation(pos.lat, pos.lng);
        //   }, 1000);
        // }
        // new google.maps.Marker({
        //   position: pos,
        //   map: this.map,
        //   title: 'Your Location',
        // });
      });
    }
  }
  updateNewLocation(lt, ln) {
    const newLocation = new google.maps.LatLng(lt, ln);
    this.placeMarker(newLocation);
    this.map.setCenter(newLocation); // Center the map on the new location
  }
  setDefaultMapCenter() {
    const defaultCenter = { lat: this.lat, lng: this.lng }; // Default location
    this.map.setCenter(defaultCenter);
    this.map.setZoom(8);
  }
  selectAddress(event) {
    // Get the selected value (address ID in this case)
    const selectedAddressId = +event.target.value;

    // Find the selected address object based on the ID
    const selectedAddress = this.myAddresses.find(
      (address) => address.id === selectedAddressId
    );
    console.log(selectedAddressId);
    console.log(selectedAddress);
    if (selectedAddress) {
      console.log(selectedAddress);
      localStorage.setItem('addressId', selectedAddress.id);
      this.lat = selectedAddress.latLng.split(',')[0];
      this.lng = selectedAddress.latLng.split(',')[1];
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
      this.address =
        selectedAddress.country +
        ' - ' +
        selectedAddress.city +
        ' - ' +
        selectedAddress.addressName;
    }
  }
  current_lang = localStorage.getItem('current_lang') || 'en';
  searchQuery: string;
  address = localStorage.getItem('address');
  lat = +localStorage.getItem('lat');
  lng = +localStorage.getItem('lng');
  newLat = +localStorage.getItem('lat');
  newLng = +localStorage.getItem('lng');
  newAddress = localStorage.getItem('address');
  public closeBanner: boolean | undefined;
  shopTypes: any[] = [];
  countries: any[] = [];
  countryId: number = 0;
  isApiLoaded = true;
  options: any = {
    // types: ['address'],
    // types: ['hospital', 'pharmacy', 'bakery', 'country', 'resturant', 'bank'],//Checkout https://developers.google.com/maps/documentation/javascript/supported_types
    componentRestrictions: {
      country: this.authService.getCountryCode(),
    },
  };
  options2: any = {
    // types: ['address'],
    // types: ['hospital', 'pharmacy', 'bakery', 'country', 'resturant', 'bank'],//Checkout https://developers.google.com/maps/documentation/javascript/supported_types
    componentRestrictions: {
      country: this.authService.getCountryCode(),
    },
  };
  handleAddressChange(address: google.maps.places.PlaceResult) {
    this.address = address.formatted_address;
    this.lng = address.geometry.location.lng();
    this.lat = address.geometry.location.lat();
    console.log(address.formatted_address);
    localStorage.setItem('address', this.address);
    localStorage.setItem('lng', this.lng.toString());
    localStorage.setItem('lat', this.lat.toString());
    // Extracting additional address components
    const addressComponents = address.address_components;
    const addressInfo = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      buildingNumber: '',
      apartmentNumber: '',
      region: '',
    };
    console.log(addressComponents);
    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes('street_number')) {
        addressInfo.streetNumber = component.long_name;
      } else if (types.includes('route')) {
        addressInfo.streetName = component.long_name;
      } else if (types.includes('locality')) {
        addressInfo.city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressInfo.state = component.long_name;
      } else if (types.includes('country')) {
        addressInfo.country = component.long_name;
      } else if (types.includes('postal_code')) {
        addressInfo.postalCode = component.long_name;
      } else if (
        types.includes('sublocality') ||
        types.includes('sublocality_level_1')
      ) {
        addressInfo.region = component.long_name;
      } else if (types.includes('premise')) {
        addressInfo.buildingNumber = component.long_name;
      } else if (types.includes('subpremise')) {
        addressInfo.apartmentNumber = component.long_name;
      }
    });

    // Logging the extracted address components
    console.log('Street Number:', addressInfo.streetNumber);
    console.log('Street Name:', addressInfo.streetName);
    console.log('City:', addressInfo.city);
    console.log('State:', addressInfo.state);
    console.log('Country:', addressInfo.country);
    console.log('Postal Code:', addressInfo.postalCode);

    // Storing additional address components in local storage
    localStorage.setItem('streetNumber', addressInfo.streetNumber);
    localStorage.setItem('streetName', addressInfo.streetName);
    localStorage.setItem('city', addressInfo.city);
    localStorage.setItem('state', addressInfo.state);
    localStorage.setItem('country', addressInfo.country);
    localStorage.setItem('postalCode', addressInfo.postalCode);
    localStorage.setItem('buildingNumber', addressInfo.buildingNumber);
    localStorage.setItem('apartmentNumber', addressInfo.apartmentNumber);
    localStorage.setItem('region', addressInfo.region);
    const addressData = {
      apartmentNo: addressInfo.apartmentNumber,
      buildingNo: addressInfo.buildingNumber,
      city: addressInfo.city,
      country: addressInfo.country,
      latLng: `${this.lat},${this.lng}`,
      street: `${addressInfo.streetNumber} ${addressInfo.streetName}`,
      region: addressInfo.region,
      phoneNumber: '', // Add phoneNumber if available
      saveAddressAs: '', // Add saveAddressAs if needed
      isSaveAddress: true,
    };
    localStorage.setItem('addressData', JSON.stringify(addressData));
  }

  addressInfoModal = {
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    buildingNumber: '',
    apartmentNumber: '',
    region: '',
  };
  handleAddressChange2(address: google.maps.places.PlaceResult) {
    this.newAddress = address.formatted_address;
    this.newLng = address.geometry.location.lng();
    this.newLat = address.geometry.location.lat();
    console.log(address.formatted_address);
    // localStorage.setItem('address', this.address);
    // localStorage.setItem('lng', this.lng.toString());
    // localStorage.setItem('lat', this.lat.toString());
    this.updateLocation();
    const addressComponents = address.address_components;
    this.addressInfoModal = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      buildingNumber: '',
      apartmentNumber: '',
      region: '',
    };

    addressComponents.forEach((component) => {
      const types = component.types;

      // if (types.includes('street_number')) {
      //   this.addressInfoModal.streetNumber = component.long_name;
      // } else if (types.includes('route')) {
      //   this.addressInfoModal.streetName = component.long_name;
      // } else if (types.includes('locality')) {
      //   this.addressInfoModal.city = component.long_name;
      // } else if (types.includes('administrative_area_level_1')) {
      //   this.addressInfoModal.state = component.long_name;
      // } else if (types.includes('country')) {
      //   this.addressInfoModal.country = component.long_name;
      // } else if (types.includes('postal_code')) {
      //   this.addressInfoModal.postalCode = component.long_name;
      // }
      if (types.includes('street_number')) {
        this.addressInfoModal.streetNumber = component.long_name;
      } else if (types.includes('route')) {
        this.addressInfoModal.streetName = component.long_name;
      } else if (types.includes('locality')) {
        this.addressInfoModal.city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        this.addressInfoModal.state = component.long_name;
      } else if (types.includes('country')) {
        this.addressInfoModal.country = component.long_name;
      } else if (types.includes('postal_code')) {
        this.addressInfoModal.postalCode = component.long_name;
      } else if (
        types.includes('sublocality') ||
        types.includes('sublocality_level_1')
      ) {
        this.addressInfoModal.region = component.long_name;
      } else if (types.includes('premise')) {
        this.addressInfoModal.buildingNumber = component.long_name;
      } else if (types.includes('subpremise')) {
        this.addressInfoModal.apartmentNumber = component.long_name;
      }
    });
  }
  saveAddress(id, nameEn) {
    localStorage.setItem('shopTypeId', id);
    localStorage.setItem('shopTypeName', nameEn);
    if (this.address) {
      localStorage.setItem('address', this.address);
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
    }
    if (localStorage.getItem('comId')) {
      console.log('comId');
      console.log(localStorage.getItem('comId'));
      if (this.address) {
        this.router
          .navigate(['/checkout'], {
            queryParams: { id: +localStorage.getItem('comId') },
          })
          .then(() => {
            localStorage.removeItem('comId');
          });
        return;
      }
    }
    this.router.navigate(['/companies'], { queryParams: { id: id } });
  }
  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private authService: AuthService,
    private renderer: Renderer2,
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private _authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private modalService: NgbModal,
    private lookupsService: LookupsService
  ) {}
  toggleBanner() {
    this.closeBanner = !this.closeBanner;
  }
  @ViewChild('autocompleteInputModal') autocompleteInputModal: ElementRef;

  @ViewChild('searchInput') searchInputRef!: ElementRef;
  updateInputModalValue(): void {
    const inputElement: HTMLInputElement =
      this.autocompleteInputModal.nativeElement;
    inputElement.value = ''; // Clear input value
    setTimeout(() => {
      inputElement.dispatchEvent(new Event('input')); // Trigger input event
    });
  }
  updateInputValue(): void {
    const inputElement: HTMLInputElement = this.searchInputRef.nativeElement;
    inputElement.value = ''; // Clear input value
    setTimeout(() => {
      inputElement.dispatchEvent(new Event('input')); // Trigger input event
    });
  }
  autoComplete: google.maps.places.Autocomplete;
  autoComplete2: google.maps.places.Autocomplete;
  getCountryId(id) {
    this.address = '';
    // console.log(this.authService.getCountryCode())
    this.autoComplete.setOptions({
      componentRestrictions: { country: this.authService.getCountryCode() },
    });
    this.autoComplete2.setOptions({
      componentRestrictions: { country: this.authService.getCountryCode() },
    });
    // this.options.componentRestrictions = { country: this.authService.getCountryCode() || 'jo' };
    this.searchAddress = ''; // Clear search input value when changing country
    // this.updateInputValue(); // Update input field value

    this.countryId = +id;
    // localStorage.setItem('countryId', id);
    this.getShopTypes();
    // this.openAddressModal();
  }
  loadScript() {
    return new Promise((resolve, reject) => {
      // const allScripts = document.getElementsByTagName('script');
      // for (let i = 0; i < allScripts.length; i++) {
      //   if (
      //     allScripts[i].src.indexOf(
      //       'key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4'
      //     ) > 0
      //   ) {
      //     this.renderer.removeChild(document.body, allScripts[i]);
      //   }
      // }
      // [].filter
      //   .call(
      //     allScripts,
      //     (scpt) => scpt.src.indexOf('key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4') >= 0
      //   )[0]
      //   .remove();
      // const element = this.document.createElement('script');
      // element.type = 'text/javascript';
      // element.src =
      //   'https://maps.googleapis.com/maps/api/js?key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4&libraries=places&language=en'; //<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE&libraries=places&sensor=false"></script>
      // element.onload = resolve;
      // element.onerror = reject;
      // this.elementRef.nativeElement.appendChild(element);
    });
  }
  handlePlaceChanged(): void {
    const place = this.autoComplete.getPlace();
    if (place && place.formatted_address) {
      this.handleAddressChange(place);
    }
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe((res) => {
      console.log(this.countries);
      this.countries = res.data;
      if (!localStorage.getItem('countryId')) {
        // this.openAddressModal();
        this.modalRef = this.modalService.open(this.modalTemplateCountry, {
          centered: true,
          // windowClass: 'modal-large-custom',
          ariaLabelledBy: 'modal-basic-title',
        });
      } else if (!this.address) {
        const currentCountryId = +localStorage.getItem('countryId');
        let country = this.countries.find(c=>c.id == currentCountryId)
        this.changeCountry(country);
      }
    });
  }
  ngOnInit(): void {
    console.log(this.authService.getCountryCode());
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
    // this.loadGoogleMaps()
    // this.options.componentRestrictions.country =
    //   this.authService.getCountryCode() || 'jo';
    setTimeout(() => {
      this.autoComplete = new google.maps.places.Autocomplete(
        this.searchInputRef.nativeElement,
        {
          componentRestrictions: {
            country: this.authService.getCountryCode() || 'jo',
          }, // Default country
        }
      );
      this.autoComplete.addListener(
        'place_changed',
        this.handlePlaceChanged.bind(this)
      );
      this.autoComplete2 = new google.maps.places.Autocomplete(
        this.autocompleteInputModal.nativeElement,
        {
          componentRestrictions: {
            country: this.authService.getCountryCode() || 'jo',
          }, // Default country
        }
      );
      this.autoComplete2.addListener(
        'place_changed',
        this.handlePlaceChanged.bind(this)
      );
      
    }, 1000);
    // this.loadScript().then(() => {
    //   this.isApiLoaded = true;
    // });
    this.getShopTypes();
    // if (!this.address) this.getCurrentLocation();
    this.getCountries();
  }
  changeCountry(country) {
    const currentCountryId = +localStorage.getItem('countryId');
    if (country.id != currentCountryId) {
      // Clear the localStorage items related to the previous address
      localStorage.removeItem('address');
      localStorage.removeItem('lng');
      localStorage.removeItem('lat');
      localStorage.removeItem('streetNumber');
      localStorage.removeItem('streetName');
      localStorage.removeItem('city');
      localStorage.removeItem('state');
      localStorage.removeItem('country');
      localStorage.removeItem('postalCode');
      localStorage.removeItem('buildingNumber');
      localStorage.removeItem('apartmentNumber');
      localStorage.removeItem('region');
    }
    localStorage.setItem('countryId', country.id);
    this.getShopTypes(country.id)
    localStorage.setItem('flagImage', country.flagImage);
    localStorage.setItem('countryCode', country.countryCode);
    localStorage.setItem('currencyName', country.currencyName);
    localStorage.setItem('currencyPrecision', country.currencyPrecision);
    localStorage.setItem('currencySymbol', country.currencySymbol);
    localStorage.setItem('currencySymbolAr', country.currencySymbolAr);
    localStorage.setItem('currencySymbolEn', country.currencySymbolEn);
    let arr = country.latLng.split(',');
    this.lat = +arr[0];
    this.lng = +arr[1];
    localStorage.setItem('lat', this.lat + '');
    localStorage.setItem('lng', this.lng + '');

    $('.close').trigger('click');
    const modalRef = this.modalService.open(ChangeAddressModalComponent, {
      size: 'lg', // Adjust size as needed ('sm', 'lg', 'xl')
      backdrop: 'static', // Optional: Prevent modal from closing when clicking outside
      keyboard: true // Optional: Allow closing with the escape key
    });
    
    // Pass input to the modal
    modalRef.componentInstance.countryId = country.id;
    modalRef.componentInstance.latLng = country.latLng;
    modalRef.componentInstance.countryCode = country.countryCode;
    // Handle data after modal close if needed
    modalRef.result.then(
      (result) => {
        this.address = result.newAddress;
        console.log('Modal closed with:', result);
      },
      (reason) => {
        console.log('Modal dismissed:', reason);
      }
    );
    return
    this.i = 1;
    console.log(country);
    localStorage.setItem('countryId', country.id);
    localStorage.setItem('flagImage', country.flagImage);
    localStorage.setItem('countryCode', country.countryCode);
    localStorage.setItem('currencyName', country.currencyName);
    localStorage.setItem('currencyPrecision', country.currencyPrecision);
    localStorage.setItem('currencySymbol', country.currencySymbol);
    localStorage.setItem('currencySymbolAr', country.currencySymbolAr);
    localStorage.setItem('currencySymbolEn', country.currencySymbolEn);
    // let arr = country.latLng.split(',');
    // this.lat = +arr[0];
    // this.lng = +arr[1];
    // this.newLat = +arr[0];
    // this.newLng = +arr[1];
    localStorage.setItem('lat', this.lat + '');
    localStorage.setItem('lng', this.lng + '');
    this.options = {
      componentRestrictions: {
        country: country.countryCode,
      },
    };
    this.options2 = {
      componentRestrictions: {
        country: country.countryCode,
      },
    };
    this.autoComplete.setOptions({
      componentRestrictions: { country: country.countryCode },
    });
    this.autoComplete2.setOptions({
      componentRestrictions: { country: country.countryCode },
    });
    this.modalService.dismissAll();
    this.openAddressModal();
  }
  // ngAfterViewInit(){
  //   console.log(this.options)
  // }
  arrayOfShopTypes = [];
  getShopTypes(id = 0) {
    this.getMyAddresses();
    this.countryId = id == 0 ? +localStorage.getItem('countryId') || 0 : id;
    this.catalogService.getAllShopTypes(+this.countryId).subscribe((res) => {
      this.shopTypes = res.data;
      this.arrayOfShopTypes = generateNumberArray(0, res.data.length - 1);
    });
  }
  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str); //!! return boolean
  }
  onInputChange() {
    if (this.searchQuery) {
      const apiKey = 'AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4';
      const apiUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${this.searchQuery}&key=${apiKey}`;

      this.http.get(apiUrl).subscribe((response: any) => {
        if (response && response.results) {
          const addresses = response.results.map(
            (result) => result.formatted_address
          );
          console.log(addresses);
        }
      });
    }
  }
  initializeMap() {
    const mapProp = {
      center: new google.maps.LatLng(
        this.lat != 0 ? this.lat : 37.7749,
        this.lng != 0 ? this.lng : -122.4194
      ),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    setTimeout(() => {
      console.log('lat');
      console.log(this.lat);
      //  this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(
          this.lat != 0 ? this.lat : 29.2404461,
          this.lng != 0 ? this.lng : 47.9684709
        ), // Default to San Francisco coordinates
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false, // Disable maximize control
        streetViewControl: false, // Disable street view (person icon)
        zoomControl: false, // Disable zoom control
      };
      const mapContainer = document.getElementById('mapContainer');

      this.map = new google.maps.Map(mapContainer, mapOptions);

      this.map.addListener('click', (event: any) => {
        this.placeMarker(event.latLng);
      });

      setTimeout(() => {
        this.updateLocation();
      }, 1000);
      this.getCurrentLocation();
    }, 1000);

    setTimeout(() => {
      this.setMapOnAll(null);
    }, 2000);
  }
  setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }
  markers: google.maps.Marker[] = []; // Initialize an empty array to store markers
  clearMarkers(map: google.maps.Map | null) {
    this.markers.forEach((marker) => {
      marker.setMap(map); // Clear the marker from the map
    });
    this.markers = []; // Reset the markers array
  }
  placeMarker(location: any) {
    console.log('location');
    console.log(location);
    this.clearMarkers(null); // Clear all markers before placing a new one
    if (this.marker) {
      // If a marker already exists, update its position
      this.marker.setPosition(location);
    } else {
      // If no marker exists, create a new one
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
      });
    }
    if (this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null); // Remove the current location marker
      this.currentLocationMarker = null; // Reset the currentLocationMarker reference
    }
    this.markers.push(this.marker); // Add the new marker to the array

    this.newLat = location.lat();
    this.newLng = location.lng();
    // this.branchForm.patchValue({
    //   latLng: this.latitude + ',' + this.longitude,
    // });
    console.log(this.lat);
    console.log(this.lng);
    // Display the marker on the map if it's not already visible
    if (!this.marker.getMap()) {
      this.marker.setMap(this.map);
    }

    this.geocoder.geocode(
      { location: location },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.searchAddress = results[0].formatted_address;
            console.log('results[0].formatted_address');
            console.log(results[0].formatted_address);
            this.newAddress = results[0].formatted_address;

            const addressComponents = results[0].address_components;
            this.addressInfoModal = {
              streetNumber: '',
              streetName: '',
              city: '',
              state: '',
              country: '',
              postalCode: '',
              buildingNumber: '',
              apartmentNumber: '',
              region: '',
            };

            addressComponents.forEach((component) => {
              const types = component.types;

              if (types.includes('street_number')) {
                this.addressInfoModal.streetNumber = component.long_name;
              } else if (types.includes('route')) {
                this.addressInfoModal.streetName = component.long_name;
              } else if (types.includes('locality')) {
                this.addressInfoModal.city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                this.addressInfoModal.state = component.long_name;
              } else if (types.includes('country')) {
                this.addressInfoModal.country = component.long_name;
              } else if (types.includes('postal_code')) {
                this.addressInfoModal.postalCode = component.long_name;
              } else if (
                types.includes('sublocality') ||
                types.includes('sublocality_level_1')
              ) {
                this.addressInfoModal.region = component.long_name;
              } else if (types.includes('premise')) {
                this.addressInfoModal.buildingNumber = component.long_name;
              } else if (types.includes('subpremise')) {
                this.addressInfoModal.apartmentNumber = component.long_name;
              }
            });
            let streetName = '';

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('route')) {
                streetName = addressComponents[i].long_name;
                // this.branchForm.patchValue({
                //   street: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('postal_code')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   postalCode: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('street_number')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   buildingNumber: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('subpremise')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   apartmentNumber: addressComponents[i].long_name,
                // });
                break;
              }
            }
            console.log(this.addressInfoModal);
            const addressData = {
              apartmentNo: this.addressInfoModal.apartmentNumber,
              buildingNo: this.addressInfoModal.buildingNumber,
              city: this.addressInfoModal.city,
              country: this.addressInfoModal.country,
              latLng: `${this.lat},${this.lng}`,
              street: `${this.addressInfoModal.streetNumber} ${this.addressInfoModal.streetName}`,
              region: this.addressInfoModal.region,
              phoneNumber: '', // Add phoneNumber if available
              saveAddressAs: '', // Add saveAddressAs if needed
              isSaveAddress: true,
            };
            localStorage.setItem('addressData', JSON.stringify(addressData));
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  selectAddressFromMap() {
    if (this.newLat != 0) {
      this.lat = this.newLat;
      this.lng = this.newLng;
      this.address = this.newAddress;
      localStorage.setItem('address', this.address);
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
      this.modalService.dismissAll();
      this.newLat = 0;
      this.newLng = 0;
      this.newAddress = '';
      // localStorage.setItem('streetNumber', this.addressInfoModal.streetNumber);
      // localStorage.setItem('streetName', this.addressInfoModal.streetName);
      // localStorage.setItem('city', this.addressInfoModal.city);
      // localStorage.setItem('state', this.addressInfoModal.state);
      // localStorage.setItem('country', this.addressInfoModal.country);
      // localStorage.setItem('postalCode', this.addressInfoModal.postalCode);
      localStorage.setItem('streetNumber', this.addressInfoModal.streetNumber);
      localStorage.setItem('streetName', this.addressInfoModal.streetName);
      localStorage.setItem('city', this.addressInfoModal.city);
      localStorage.setItem('state', this.addressInfoModal.state);
      localStorage.setItem('country', this.addressInfoModal.country);
      localStorage.setItem('postalCode', this.addressInfoModal.postalCode);
      localStorage.setItem(
        'buildingNumber',
        this.addressInfoModal.buildingNumber
      );
      localStorage.setItem(
        'apartmentNumber',
        this.addressInfoModal.apartmentNumber
      );
      localStorage.setItem('region', this.addressInfoModal.region);
    }
  }
  loadGoogleMaps() {
    this.mapsAPILoader.load().then(() => {
      this.initializeMap();
    });
  }
  updateLocation() {
    // const newLocation = new google.maps.LatLng(this.lat, this.lng);
    // this.placeMarker(newLocation);
    const newLocation = new google.maps.LatLng(
      this.newLat ? this.newLat : this.latitude,
      this.newLng ? this.newLng : this.longitude
    );
    this.placeMarker(newLocation);
    this.map.setCenter(newLocation); // Center the map on the new location
  }
  ngAfterViewInit() {
    // Initialize map only when the modal is fully shown
    // $('#modalSelectAddress').on('shown.bs.modal', () => {
    //   this.loadGoogleMaps();
    // });
  }
}
