<div id='sideBarContent'>
  <h3 class='text-center mt-4'>{{'Basket' | translate}}</h3>
  <button class="btn btn-danger close-basket-btn text-right-style" (click)="toggleSideBar()"><i class="fa fa-times"></i></button>
  <div class="mb-2" *ngFor="let order of orders;let i = index">
    <div class="product-card p-3">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column">
            <p *ngIf="order.productName" class="basket-product-title">{{ order.productName }} <span
                class="p-2 quantity-span">
                <input readonly class="input-group-field input-group-field-basket{{i}}" min="1" type="number"
                  name="quantity{{i}}" [value]="order.quantity">
              </span></p>
            <div *ngFor="let extraDetail of order.extraDetails; let i = index" class="extra-detail text-style-right">
              <span [ngClass]="getExtraDetailClass(i)">{{ extraDetail | htmlToPlaintext }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end">
          <p class="price">{{ order?.total | dynamicCurrency: settingService.getCurrencySymbol() :
            +settingService.getCurrencyPrecision()}}</p>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <span class="p-2"></span>
      <a (click)="toggleNote(i)" class="add-note add-note{{i}}" *ngIf="!isNote[i]">{{ 'Add note' | translate}}</a>
      <a (click)="toggleNote(i)" class="add-note add-note{{i}}" *ngIf="isNote[i]">{{ 'Hide note'| translate}}</a>
      <div class="plus-minus-input d-flex mx-2" [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'">
        <div class="input-group-button">
          <button (click)="plusMinusBtn(1,i,order)" type="button" class="button hollow-basket circle mx-2"
            data-quantity="minus" data-field="quantity">
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
        </div>
        <div class="input-group-button pl-2">
          <button (click)="plusMinusBtn(2,i,order)" type="button" class="button hollow-basket circle"
            data-quantity="plus" data-field="quantity">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isNote[i]" class="wrapper mt-1">
      <textarea [(ngModel)]="order.notes" maxlength="160" (keyup)="keyupFun($event,i)" name="" id="" cols="5"
        rows="2"></textarea>
      <div id="the-count{{i}}" class="the-count">
        <span id="current{{i}}">0</span>
        <span id="maximum{{i}}">/ 160</span>
      </div>
    </div>
  </div>
  <div *ngIf="orders.length > 0" class="d-flex mt-2">
    <div class="d-flex p-2 flex-column">
      <span>{{'SubTotal' | translate}}</span>
      <span>{{'DeliveryCosts' | translate}}</span>
      <span>{{'AdditionalServicePrice' | translate}}</span>
      <h5 class="font-weight-bold">{{'Total' | translate}}</h5>
    </div>
    <div class="d-flex flex-column p-2 ml-auto">
      <span>{{subTotal() | dynamicCurrency: settingService.getCurrencySymbol() :
        +settingService.getCurrencyPrecision()}}</span>
      <span>{{deliveryCost | dynamicCurrency: settingService.getCurrencySymbol() :
        +settingService.getCurrencyPrecision()}}</span>
        <span>{{orders[0].additionalServicePrice | dynamicCurrency: settingService.getCurrencySymbol() :
        +settingService.getCurrencyPrecision()}}</span>
      <h5>{{orders[0].additionalServicePrice + orders[0].deliverFees + subTotal() + deliveryCost | dynamicCurrency: settingService.getCurrencySymbol() :
        +settingService.getCurrencyPrecision()}}</h5>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center pt-4" *ngIf="orders.length == 0">
    <p><img src="assets/img/shopping-bag.png" height="40" width="40" alt=""></p>
    <p class="font-weight-bold">{{'Fill Your Bag' | translate}}</p>
    <p>{{'Your bag is still empty' | translate}} 🙁</p>
  </div>
</div>

<!-- <button *ngIf="orders.length" [disabled]="minOrder > (orders[0].deliverFees + subTotal())" (click)="makeOrderBtn()" id='makeOrder' type="button" -->
<div class="d-flex">
  <button *ngIf="orders.length" (click)="makeOrderBtn()" id='makeOrder' type="button"
    class="btn btn-warning pt-1 pb-2 mt-lg-5 pt-1 pb-2 w-100 mt-lg-5">
    <span>{{'Checkout' | translate}} (<span>{{orders[0].additionalServicePrice + orders[0].deliverFees + subTotal() + deliveryCost | dynamicCurrency:
        settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</span>)</span>
  </button>
  <button class="btn btn-warning show-basket-btn pt-1 pb-2 mt-lg-5" (click)="toggleSideBar()"
    *ngIf="!isSideBarVisible">
    <i class="bx bx-basket text-white"></i>
  </button>
</div>