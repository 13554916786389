<!-- <section class="browse-cat-100 u-line section-padding2 header">
  <div class="container">
    <div class="row">
      <div *ngIf="globalCategories.length == 0" class="col-12">
        <div class="item">
          <ngx-skeleton-loader count="1" animation="progress" [theme]="{
            'border-radius': '5px',
            height: '40px',
            border: '1px solid white'
          }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-12">
        <div class="categories-swiper-container swiper-container">
          <div class="swiper-wrapper swipper-width-auto">
              <ng-container *ngFor="let item of globalCategories; let i=index">
                <div class="swiper-slide">
                  <a [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)" class="categories p-2">
                    <span class="text-light-black2 cat-name">{{item.nameEn}}</span>
                  </a>
                </div>
              </ng-container>
          </div>
          <div class="swiper-button-next swiper-button-next2"></div>
          <div class="swiper-button-prev swiper-button-prev2"></div>
        </div>
      </div>
    </div>
  </div>
</section> -->


<!-- <ng-container *ngIf="globalCategories.length == 0" class="swiper-slide">
  <ngx-skeleton-loader count="1" animation="progress" [theme]="{
      'border-radius': '5px',
      height: '40px',
      border: '1px solid white'
    }"></ngx-skeleton-loader>
</ng-container> -->

<!-- <div class="popular-item-slider instagram-slider">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let item of globalCategories; let i=index">
      <a class="categories" [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)">
        <div class="icon text-custom-white">
          <img src="assets/img/restaurants/125x125/cuisine-11.jpg" class="rounded-circle" alt="categories">
        </div>
        <span class="text-light-black cat-name">{{ current_lang == 'en' ? item.nameEn : item.nameAr }}</span>
      </a>
    </div>
  </div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div> -->

<!-- <div class="mx-3 scroll-menu-container">
  <button class="scroll-button left" (click)="scrollLeft()">
    <img src="assets/img/left-arrow.png" alt="Scroll Left">
  </button>
  <div class="scroll-menu" #scrollMenu>
    <div class="menu-item" *ngFor="let item of globalCategories; let i=index">{{ current_lang === 'en' ? item.nameEn : item.nameAr }}</div>
  </div>
  <button class="scroll-button right" (click)="scrollRight()">
    <img src="assets/img/right-arrow.png" alt="Scroll Right">
  </button>
</div> -->
<!-- <div class="scroll-menu-container">
  <button class="scroll-button left" (click)="scrollLeft()">
    <img src="assets/img/left-arrow.png" alt="Scroll Left">
  </button>
  <div class="scroll-menu" #scrollMenu (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)">
    <div [id]="'cat' + i" (click)="toggleStyle(i,item.id)" class="categories menu-item" *ngFor="let item of globalCategories; let i=index"><span>{{ current_lang == 'en' ? item.nameEn : item.nameAr }}</span></div>
  </div>
  <button class="scroll-button right" (click)="scrollRight()">
    <img src="assets/img/right-arrow.png" alt="Scroll Right">
  </button>
</div> -->

<div class="uk-container" uk-slider *ngIf="globalCategories.length > 0">
  <div class="uk-position-relative uk-visible-toggle uk-light" dir="ltr">

    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m d-flex">
      <li class="menu-item" *ngFor="let item of globalCategories; let i=index" [id]="'cat' + i" (click)="toggleStyle(i,item.id)" class="categories menu-item">
        <div class="uk-position-center uk-panel">
          <span>{{ current_lang === 'en' ? item.nameEn : item.nameAr }}</span>
        </div>
      </li>
    </ul>
    <!-- Navigation arrows centered -->
    <a class="uk-position-center-left uk-position-small uk-visible@m" href="#" uk-slidenav-previous uk-slider-item="previous" style="color: #f44336;"></a>
    <a class="uk-position-center-right uk-position-small uk-visible@m" href="#" uk-slidenav-next uk-slider-item="next" style="color: #f44336;"></a>

  </div>
</div>


