import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
  CustomerRegisterModel,
  LoginModel,
} from 'src/app/models-classes/auth/login-model.model';
import { Order } from 'src/app/models-classes/order/order.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { SettingsService } from 'src/app/services/settings.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { AngularDeviceInformationService } from 'angular-device-information';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  Checkout: any;
  htmlForm: any;
  htmlaction: any;
  paymentMethodText = 'Select your payment method';
  paymentMethods = {
    Cash: 0,
    // CashPOS: 1,
    // ApplePay: 2,
    Knet: 3,
    Card: 4,
    WalletPay: 5,
  };
  paymentMethodIcons = {
    Cach: {
      iconClass: '/assets/img/cards/cash.png',
      numericValue: 0,
      text: 'Cach',
    },
    // POS: {
    //   iconClass: '/assets/img/cards/pos.png',
    //   numericValue: 1,
    //   text: 'POS',
    // },
    // ApplePay: {
    //   iconClass: '/assets/img/cards/apple-pay.png',
    //   numericValue: 2,
    //   text: 'Apple',
    // },
    Knet: {
      iconClass: '/assets/img/cards/knet.png',
      numericValue: 3,
      text: 'Knet',
    },
    Card: {
      iconClass: '/assets/img/cards/credit-card.png',
      numericValue: 4,
      text: 'Card',
    },
    WalletPay: {
      iconClass: '/assets/img/cards/wallet.png',
      numericValue: 5,
      text: 'Wallet',
    },
  };
  isApple = false;
  companyId = 0;
  addressId = 0;
  current_lang = localStorage.getItem('current_lang') || 'en';
  loginStatus$: Observable<boolean>;
  submitted1 = false;
  submitted2 = false;
  submittedPlace = false;
  confirm_email = false;
  show_email_feild = true;
  visible = true;
  submitted = false;
  paymentNumber = 0;
  email_confirm = false;
  authType = 'Sign in';
  placeOrderForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  resetConfirmForm: UntypedFormGroup;
  confirmEmailForm: UntypedFormGroup;
  authFormType = AuthFormType.SignIn;
  visible1 = true;
  order: Order;
  lastAddress: any;
  orders: any[] = [];
  cities: any[] = [];
  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private _authService: AuthService,
    private _toast: ToastrService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public settingService: SettingsService,
    private lookupService: LookupsService,
    private translateService: TranslateService,
    private deviceInformationService: AngularDeviceInformationService
  ) {}
  @ViewChild('mapContainer', { static: true }) gmap: ElementRef;
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  searchAddress: string = '';
  marker: google.maps.Marker;
  markers: google.maps.Marker[] = []; // Initialize an empty array to store markers
  currentLocationMarker: google.maps.Marker | null = null; // Initialize marker for current location
  latitude = localStorage.getItem('lat') || '';
  longitude = localStorage.getItem('lng') || '';
  cost = +localStorage.getItem('deliveryCost') || 0;
  searchLocation(event) {
    this.geocoder.geocode(
      { address: event.target.value },
      (results: any, status: any) => {
        if (status === 'OK') {
          this.map.setCenter(results[0].geometry.location);
          new google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location,
          });
        }
      }
    );
  }
  placeMarker(location: any) {
    this.clearMarkers(); // Clear all markers before placing a new one

    if (this.marker) {
      // If a marker already exists, update its position
      this.marker.setPosition(location);
    } else {
      // If no marker exists, create a new one
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
      });
    }
    if (this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null); // Remove the current location marker
      this.currentLocationMarker = null; // Reset the currentLocationMarker reference
    }
    this.markers.push(this.marker); // Add the new marker to the array

    this.latitude = location.lat();
    this.longitude = location.lng();

    // Display the marker on the map if it's not already visible
    if (!this.marker.getMap()) {
      this.marker.setMap(this.map);
    }

    this.geocoder.geocode(
      { location: location },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log(results[0]);
            this.searchAddress = results[0].formatted_address;
            const addressComponents = results[0].address_components;
            let streetName = '';

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('route')) {
                streetName = addressComponents[i].long_name;
                this.placeOrderForm.patchValue({
                  street: addressComponents[i].long_name,
                });
                break;
              }
            }

            // for (let i = 0; i < addressComponents.length; i++) {
            //   const types = addressComponents[i].types;
            //   if (types.includes('postal_code')) {
            //     this.registerForm.patchValue({
            //       postalCode: addressComponents[i].long_name,
            //     });
            //     break;
            //   }
            // }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('street_number')) {
                this.placeOrderForm.patchValue({
                  buildingNumber: addressComponents[i].long_name,
                });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('subpremise')) {
                this.placeOrderForm.patchValue({
                  apartmentNumber: addressComponents[i].long_name,
                });
                break;
              }
            }
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  loadAddressData() {
    try {
      this.setPlaceOrderForm();
      const addressData = localStorage.getItem('addressData');
      if (addressData) {
        const parsedData = JSON.parse(addressData);
        console.log(parsedData);
        if (parsedData) {
          this.placeOrderForm.patchValue({
            apartmentNumber: parsedData.apartmentNo || '',
            buildingNumber: parsedData.buildingNo || '',
            city: parsedData.city || '',
            country: parsedData.country || '',
            latLng: parsedData.latLng || '',
            street: parsedData.street || '',
            region: parsedData.region || '',
            phoneNumber: parsedData.phoneNumber || '',
            addressName: parsedData.saveAddressAs || '',
            isSaveAddress: parsedData.isSaveAddress || false,
          });
          const city = this.placeOrderForm.get('city');
          const country = this.placeOrderForm.get('country');
          if(parsedData.city){
            city.disable()
          }
          if(parsedData.country)
            country.disable()
        }
      }
    } catch (error) {
      console.error('Failed to load address data from localStorage:', error);
    }
  }
  ngOnInit(): void {
    // let addressData;
    // this.setPlaceOrderForm();
    // console.log(localStorage.getItem('addressData'))
    // if (localStorage.getItem('addressData')) {
    //   let addressData = JSON.parse(localStorage.getItem('addressData'));
    //   this.placeOrderForm.patchValue({
    //     apartmentNumber: addressData.apartmentNo,
    //     buildingNumber: addressData.buildingNo,
    //     city: addressData.city,
    //     country: addressData.country,
    //     latLng: addressData.latLng,
    //     street: addressData.street,
    //     region: addressData.region,
    //     phoneNumber: addressData.phoneNumber,
    //     addressName: addressData.saveAddressAs,
    //     isSaveAddress: addressData.isSaveAddress,
    //   });
    // }
    // const newAddress = localStorage.getItem('newAddress');
    // if (newAddress && newAddress == 'true') {
    //   localStorage.setItem('addressData', '');
    // } else {
    //   if (addressData) {

    //   }
    // }
    this.isApple =
      this.deviceInformationService.getDeviceInfo().os.toLowerCase() == 'ios' ||
      this.deviceInformationService
        .getDeviceInfo()
        .os.toLowerCase()
        .includes('mac');
    // console.log(this.deviceInformationService.isMobile()); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(this.deviceInformationService.isTablet()); // returns if the device is a tablet (tablet iPad etc)
    // console.log(this.deviceInformationService.isDesktop()); // returns if the app is running on a Desktop browser.
    // console.log(this.deviceInformationService.getDeviceType()); // returns if the app is running on a Desktop browser.
    // console.log(this.deviceInformationService.getDeviceInfo().os); // returns os name like Windows/Andtoid/iOS/Linux/Mac OS X etc
    // console.log(this.deviceInformationService.getDeviceInfo().osVersion); // returns os version like 10/8.1/7 ...etc
    // console.log(this.deviceInformationService.getDeviceInfo().browser); // returns browser name like chrome/firefox ...etc
    // console.log(this.deviceInformationService.getDeviceInfo().browserVersion); // returns browser version as number
    // console.log(
    //   this.deviceInformationService.getDeviceInfo().browserMajorVersion
    // ); // returns full browser version as number
    // console.log(
    //   this.deviceInformationService.getDeviceInfo().screen_resolution
    // ); // returns screen size like 1390x860/640x800 ...etc
    // console.log(this.deviceInformationService.getDeviceInfo().cookies); // returns cookies enabled or no
    // console.log(this.deviceInformationService.getDeviceInfo().userAgent);

    this.companyId = Number(this.route.snapshot.queryParamMap.get('id'));
    this.orders = this.settingService.getDataByCompanyId(
      'my_orders',
      this.companyId
    );
    console.log(this.orders);
    if (!this.orders || this.orders.length == 0) this.router.navigate(['/']);
    this.loginStatus$ = this._authService.isUserLoggedIn;
    this.loadAddressData();
    this.loginForm = new UntypedFormGroup({
      userNameOrEmail: new UntypedFormControl('', [
        // Validators.required,
        // Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      password: new UntypedFormControl('', [
        // Validators.required,
      ]),
      rememberMe: new UntypedFormControl(false),
    });
    this.geocoder = new google.maps.Geocoder();
    this.initializeMap();
    // this.getLastAddress();
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.latitude = position.coords.latitude + '';
        this.longitude = position.coords.longitude + '';
        this.map.setCenter(pos);
        if (this.currentLocationMarker) {
          // If the marker for the current location exists, remove it from the map
          this.currentLocationMarker.setMap(null);
          this.currentLocationMarker = null; // Reset the currentLocationMarker reference
        }
        this.currentLocationMarker = new google.maps.Marker({
          position: pos,
          map: this.map,
          title: 'Your Location',
        });
        // new google.maps.Marker({
        //   position: pos,
        //   map: this.map,
        //   title: 'Your Location',
        // });
      });
    }
  }
  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null); // Clear the marker from the map
    });
    this.markers = []; // Reset the markers array
  }
  initializeMap() {
    // const mapOptions: google.maps.MapOptions = {
    //   center: new google.maps.LatLng(37.7749, -122.4194), // Default to San Francisco coordinates
    //   zoom: 12,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // };
    // this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    // this.map.addListener('click', (event: any) => {
    //   this.placeMarker(event.latLng);
    // });

    let x = false;
    const parsedValue = Number(this.latitude);
    if (isNaN(parsedValue)) {
      // Parsing successful
      x = true;
    }
    setTimeout(() => {
      //  this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
      if (x) {
        const mapOptions: google.maps.MapOptions = {
          zoom: 14,
          // mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
        this.map.addListener('click', (event: any) => {
          this.placeMarker(event.latLng);
        });
        this.getCurrentLocation();
        return;
      }

      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(+this.latitude, +this.longitude), // Default to San Francisco coordinates
        zoom: 14,
        // mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      const myLatLng = { lat: +this.latitude, lng: +this.longitude };

      // const centerLatLng: google.maps.LatLng = mapOptions.center as google.maps.LatLng;

      this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
      // this.infoWindow = new google.maps.InfoWindow();

      this.markers.push(
        new google.maps.Marker({
          position: myLatLng,
          map: this.map,
        })
      );
      this.map.addListener('click', (event: any) => {
        this.placeMarker(event.latLng);
      });
      //  this.getCurrentLocation();
    }, 3000);
  }
  getPaymentMethodKeys() {
    return Object.keys(this.paymentMethodIcons);
  }
  urlSafe: SafeResourceUrl;

  openInNewTab(htmlString: string, type = 1) {
    //  this.htmlaction = this.getFormAction(htmlString);
    //  window.location.href = '/';
    const newWindow = window.open();
    newWindow.document.write(htmlString);
    //   function closeWin() {
    //     window.top.close();
    // }
    // setTimeout(function(){
    //     closeWin()
    // }, 1000);    // window.location.href = newWindow.location.href
  }
  getFormAction(htmlString: string): string | null {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Find the first form element
    const form = doc.querySelector('form');

    // Check if a form is found
    if (form) {
      // Get the action attribute value
      const actionValue = form.getAttribute('action');
      return actionValue;
    } else {
      console.error('No form found in the HTML string.');
      return null;
    }
  }
  subTotal() {
    let total = 0;
    
    for (let data of this.orders) {
      total += data.total;
    }
    return total;
  }
  get p() {
    return this.placeOrderForm.controls;
  }
  get f() {
    return this.registerForm.controls;
  }
  get passwordValid() {
    return this.registerForm.controls['password'].errors === null;
  }

  get requiredValid() {
    return !this.registerForm.controls['password'].hasError('required');
  }

  get minLengthValid() {
    return !this.registerForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.registerForm.controls['password'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresSpecialChars'
    );
  }
  get fR() {
    return this.resetForm.controls;
  }
  get f1() {
    return this.resetConfirmForm.controls;
  }

  get passwordValidC() {
    return this.resetConfirmForm.controls['password'].errors === null;
  }

  get requiredValidC() {
    return !this.resetConfirmForm.controls['password'].hasError('required');
  }

  get minLengthValidC() {
    return !this.resetConfirmForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValidC() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresDigit'
    );
  }

  get requiresUppercaseValidC() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValidC() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValidC() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresSpecialChars'
    );
  }
  get fCEF() {
    return this.confirmEmailForm.controls;
  }
  type = 0;
  getAddress(addressData) {
    console.log('addressData');
    console.log(addressData);
    this.placeOrderForm.patchValue({
      apartmentNumber: addressData.apartmentNo,
      buildingNumber: addressData.buildingNo,
      city: addressData.city,
      country: addressData.country,
      latLng: addressData.lat + ',' + addressData.lng,
      street: addressData.street,
      region: addressData.region,
      phoneNumber: addressData.phoneNumber,
      addressName: addressData.saveAddressAs,
      isSaveAddress: addressData.isSaveAddress,
    });
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  getCountry(country) {
    let arr = country.latLng.split(',');
    this.latitude = arr[0];
    this.longitude = arr[1];
    this.updateLocation(this.latitude, this.longitude);
  }
  updateLocation(lat, lng) {
    const newLocation = new google.maps.LatLng(lat, lng);
    this.placeMarker(newLocation);
    this.map.setCenter(newLocation); // Center the map on the new location
  }
  toggleModal(id, text) {
    this.paymentNumber = id;
    this.paymentMethodText = 'Your payment method is ' + text;
  }
  changeType(type) {
    this.type = type;
  }
  toggleEye(num) {
    if (num == 1) {
      this.visible1 = !this.visible1;
      $('.toggle-password-signin').toggleClass('fa-eye fa-eye-slash');
      var input = $($('.toggle-password-signin').attr('data-name'));
      if (input.attr('type') == 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    } else {
      this.visible = !this.visible;
      $('.toggle-password-reg').toggleClass('fa-eye fa-eye-slash');
      var input = $($('.toggle-password-reg').attr('data-name'));
      if (input.attr('type') == 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    }
  }
  confirmReset(data, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.resetConfirmForm.valid) return;
    this._authService
      .resetPassword(data.email, data.password, data.code)
      .subscribe((res) => {
        if (res.isSuccess) {
          // this._toast.success('Password changed successfully');
          this._toast.success(
            this.translateService.instant('Password changed successfully')
          );

          this.changeForm(0);
          this.resetConfirmForm.reset();
          formDirective.resetForm(); //for reset validation of form
          CustomValidators.ResetCustomValidators(this.resetConfirmForm);
        } else
          this._toast.error(
            this.translateService.instant('Password changed successfully')
          );
      });
  }
  reset(data, formDirective: FormGroupDirective) {
    this.submitted = true;
    if (!this.resetForm.valid) return;
    this._authService
      .sendResetPasswordConfirmation(data.email)
      .subscribe((res) => {
        if (res.isSuccess) {
          this.setResetConfirmForm(data.email);
          this._toast.success(
            this.translateService.instant(
              'The activation code has been sent again. Please check your email'
            )
          );
          this.show_email_feild = false;
          this.resetForm.reset();
          formDirective.resetForm();
        } else
          this._toast.error(
            this.translateService.instant('Some thing went wrong')
          );
      });
  }
  setPlaceOrderForm() {
    console.log(localStorage.getItem('defaultPhone'));
    console.log('aaaa');
    // this.getCities();
    this.placeOrderForm = this.fb.group({
      addressName: [''],
      country: [localStorage.getItem('country') || '', [Validators.required]],
      city: [localStorage.getItem('city') || '', [Validators.required]],
      latLng: [
        localStorage.getItem('lat') + ',' + localStorage.getItem('lng') || '',
      ],
      street: [localStorage.getItem('streetName') || ''],
      buildingNumber: [
        localStorage.getItem('buildingNumber') || ''
      ],
      apartmentNumber: [
        localStorage.getItem('apartmentNumber') || ''
      ],
      // city: [0, [Validators.required]],
      region: [localStorage.getItem('region') || ''],
      notes: [''],
      searchAddress: [''],
      isSaveAddress: [false],
      // firstLastName: ['', [Validators.required]],
      // mail: ['', [Validators.required, Validators.email]],
      phoneNumber: [localStorage.getItem('defaultPhone') || '', [Validators.required]],
    });

    setTimeout(() => {
      this.placeOrderForm.patchValue({
        phoneNumber:localStorage.getItem('defaultPhone') || ''
      })
    }, 1000);
  }
  setResetConfirmForm(email) {
    this.resetConfirmForm = this.fb.group({
      email: [email],
      code: ['', [Validators.required]],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
            requiresSpecialChars: true,
          }),
        ]),
      ],
    });
  }
  changeForm(num) {
    if (num == 0) {
      this.authType = 'Sign in';
      this.authFormType = AuthFormType.SignIn;
    } else if (num == 1) {
      this.authType = 'Register';
      this.authFormType = AuthFormType.Register;
      this.registerForm = this.fb.group({
        userName: ['', [Validators.required]],
        fullName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
              requiresDigit: true,
            }),
            CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
              requiresUppercase: true,
            }),
            CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
              requiresLowercase: true,
            }),
            CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
              requiresSpecialChars: true,
            }),
          ]),
        ],
      });
    } else if (num == 2) {
      this.authType = 'Forgot Password';
      this.authFormType = AuthFormType.ForgotPassword;
      this.resetForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
      });
    }
  }
  register(data, formDirective: FormGroupDirective) {
    this.submitted = true;
    let registerModel: CustomerRegisterModel = new CustomerRegisterModel();
    if (this.type == 0) {
      if (this.registerForm.invalid) {
        return;
      }
      registerModel = {
        password: data.password,
        email: data.email,
        userName: data.userName,
        fullName: data.fullName,
      };
    } else if (this.type == 1) {
      registerModel = {
        facebookToken: '1',
      };
    } else {
      registerModel = {
        googleToken: '2',
      };
    }
    this._authService.customerRegister(registerModel).subscribe((res) => {
      // let result = JSON.parse(res.data);
      // console.log(res.data.emailConfirmed);
      if (res.isSuccess) {
        if (this.type == 0) {
          this.confirm_email = true;
          this.setConfirmEmailForm();
          this.confirmEmailForm.patchValue({
            email: data.email,
          });
        }
        this.confirm_email = true;
        this._toast.success('This is a Success Toast', 'lorem ipsum asit', {
          positionClass: 'toast-top-left',
          progressBar: true,
        });
        this.registerForm.reset();
        CustomValidators.ResetCustomValidators(this.registerForm);
        // this._router.navigate(['/']);
      } else
        this._toast.error(
          this.translateService.instant('Some thing went wrong')
        );
    });
  }
  resendEmail() {
    // if (this.confirmEmailForm.value.email) {
    //   this._authService
    //     .resendOtp(this.confirmEmailForm.value.email)
    //     .subscribe((res) => {
    //       if (res.isSuccess) {
    //         this._toast.success(
    //           this.translateService.instant(
    //             'The activation code has been sent again. Please check your email'
    //           )
    //         );
    //       } else
    //         this._toast.error(
    //           this.translateService.instant('Some thing went wrong')
    //         );
    //     });
    // }
  }
  confirmMail(data, formDirective: FormGroupDirective) {
    this.submitted2 = true;
    if (!this.confirmEmailForm.valid) {
      return;
    }
    this._authService.confirmEmail(data.email, data.code).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(
          this.translateService.instant('Success'),
          'lorem ipsum asit',
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
        this.confirmEmailForm.reset();
        formDirective.resetForm();
        this.confirm_email = false;
        this.changeForm(0);
      } else
        this._toast.error(
          this.translateService.instant('Some thing went wrong'),
          this.translateService.instant('Email Confirmation'),
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
    });
  }
  getCities() {
    this.lookupService
      .getCitiesByCountryId(+localStorage.getItem('countryId'))
      .subscribe((res) => {
        this.cities = res.data;
      });
  }
  setConfirmEmailForm() {
    this.confirmEmailForm = new UntypedFormGroup({
      code: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
    });
  }
  onSubmit(data, formDirective: FormGroupDirective) {
    let loginModel: LoginModel = new LoginModel();
    if (this.type == 0) {
      loginModel = {
        password: data.password,
        userNameOrEmail: data.userNameOrEmail,
        rememberMe: data.rememberMe,
      };
    } else if (this.type == 1) {
      loginModel = {
        facebookToken: '1',
      };
    } else {
      loginModel = {
        googleToken: '2',
      };
    }
    this._authService.login(loginModel).subscribe((res) => {
      // let result = JSON.parse(res.data);
      // console.log(res.data.emailConfirmed);
      if (res.isSuccess) {
        if (this.type == 0) {
          if (!res.data.emailConfirmed) {
            // this.setConfirmEmailForm();
            this.email_confirm = true;
            this._toast.error(
              this.translateService.instant('Email not confirmed')
            );
            localStorage.removeItem('current_user');
            // this.confirmEmailForm.patchValue({
            //   email: data.userNameOrEmail,
            // });
            return;
          } else {
            $('#authModal').modal('hide');
            // this._router.navigate(['/']);
          }
        }
      } else
        this._toast.error(
          this.translateService.instant('Some thing went wrong')
        );
    });
  }
  createOrder(data, formDirective: FormGroupDirective) {
    console.log(this.placeOrderForm.valid);
  }
  getLastAddress() {
    if (this._authService.checkUserIsLogedIn())
      this._authService.getLastAddedAddresses().subscribe((res) => {
        if (res.isSuccess) {
          this.lastAddress = res.data;
          this.placeOrderForm.patchValue(res.data);
          this.latitude = res.data.latLng.split(',')[0];
          this.longitude = res.data.latLng.split(',')[1];
        }
      });
  }
  @ViewChild('paymentGatewayIframe') paymentGatewayIframe: ElementRef;

  ngAfterViewInit() {
    // Access the iframe element and set its source
  }
  setAddressId(id) {
    this.addressId = id;
    this.closeAddressModal();
  }
  toggleSideBar() {
    const sideBar = document.getElementById('sideBar2');
    const showSideBarBtn = document.getElementById('showSideBarBtn');
    if (sideBar.classList.contains('show')) {
      sideBar.classList.remove('show');
      sideBar.style.display = 'none';
      showSideBarBtn.style.display = 'block';
    } else {
      sideBar.classList.add('show');
      sideBar.style.display = 'block';
      showSideBarBtn.style.display = 'none';
    }
  }
  generateRandomString(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  placeYourOrder() {
    if (!this.placeOrderForm.valid) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this._toast.error(
        this.translateService.instant('Please check all fields')
      );
      return;
    }
    let address = localStorage.getItem('address');
    if (!localStorage.getItem('address')) {
      this._toast.warning(
        this.translateService.instant('Please select your address')
      );
      return;
    }
    let orderDate = new Date();
let localISODate = new Date(orderDate.getTime() - orderDate.getTimezoneOffset() * 60000).toISOString();
console.log('Local ISO Date:', localISODate);
    let additionalServicePrice = 0;
    if(this.orders.length > 0)
      additionalServicePrice = this.orders[0].additionalServicePrice;
    this.order = {
      isWeb: true,
      companyId: this.orders[0].companyId,
      branchId: this.orders[0].companyId,
      orderDate: localISODate,
      deliverFees: +localStorage.getItem('deliveryCost'),
      discount: this.orders[0].discount,
      total: +localStorage.getItem('deliveryCost') + this.subTotal() + additionalServicePrice,
      // newAddress: this.addressId != 0 ? null : null,
      notes: this.placeOrderForm.get('notes').value,
      // customerAddressId: this.addressId,
      apartmentNo: this.placeOrderForm.get('apartmentNumber').value,
      buildingNo: this.placeOrderForm.get('buildingNumber').value,
      city: this.placeOrderForm.get('city').value,
      country: this.placeOrderForm.get('country').value,
      latLng: this.latitude + ',' + this.longitude,
      // latLng: this.placeOrderForm.get('latLng').value,
      street: this.placeOrderForm.get('street').value,
      region: this.placeOrderForm.get('region').value,
      paymentRefNo: '',
      phoneNumber: this.placeOrderForm.get('phoneNumber').value,
      saveAddressAs: this.placeOrderForm.get('addressName').value,
      isSaveAddress: this.placeOrderForm.get('isSaveAddress').value,
      paymentSuccessToken: '',
      paymentTransactionToken: this.generateRandomString(20),
      id: 0,
      orderNumber: 1,
      paymentTypeId: this.paymentNumber,
      tax: 0,
      orderDtls: [],
    };
    //store address in localstorage
    const addressData = {
      apartmentNo: this.placeOrderForm.get('apartmentNumber').value,
      buildingNo: this.placeOrderForm.get('buildingNumber').value,
      city: this.placeOrderForm.get('city').value,
      country: this.placeOrderForm.get('country').value,
      latLng: this.latitude + ',' + this.longitude,
      street: this.placeOrderForm.get('street').value,
      region: this.placeOrderForm.get('region').value,
      phoneNumber: this.placeOrderForm.get('phoneNumber').value,
      saveAddressAs: this.placeOrderForm.get('addressName').value,
      isSaveAddress: this.placeOrderForm.get('isSaveAddress').value,
    };
    localStorage.setItem('addressData', JSON.stringify(addressData));

    this.orders.forEach((order, index) => {
      this.order.orderDtls[index] = {
        id: 0,
        productId: order.productId,
        notes: order.notes,
        price: order.oldPrice,
        quantity: order.quantity,
        total: order.total,
        discount: order.discount,
        extraDetails: '',
        tax: 0,
      };
    });
    console.log(this.placeOrderForm);
    console.log(this.placeOrderForm.value);
    // if (this.addressId == 0) {
    //   // if (!this.placeOrderForm.valid) {
    //   //   this._toast.error('Please fill delivery address or select address');
    //   //   return;
    //   // }
    //   // this.order.newAddress = {
    //   //   addressName: this.placeOrderForm.value.addressName,
    //   //   customerId: this._authService.getCustomerId(),
    //   //   id: 0,
    //   //   region: this.placeOrderForm.value.region,
    //   //   street: this.placeOrderForm.value.street,
    //   //   notes: this.placeOrderForm.value.note,
    //   //   phoneNumber: this.placeOrderForm.value.phoneNumber,
    //   //   latLng: '',
    //   //   apartmentNumber: this.placeOrderForm.value.apartmentNumber,
    //   //   blokNumber: this.placeOrderForm.value.blokNumber,
    //   //   countryId: +localStorage.getItem('countryId'),
    //   //   cityId: +this.placeOrderForm.value.city,
    //   // };
    // }
    this._authService.createOrder(this.order).subscribe((res) => {
      if (res.isSuccess) {
        localStorage.setItem('order', JSON.stringify(this.order));
        if (res.statusCode == 200) {
          this.settingService.deleteOrdersWithCompanyId(
            'my_orders',
            this.companyId
          );
          this.router.navigate(['/order-details'], {
            queryParams: { id: res.data.id },
          });
          this._toast.success(
            this.translateService.instant(
              'Your request has been successfully received'
            )
          );
          localStorage.setItem('newAddress', 'false');
        }
        // if(this.paymentNumber == 0 || this.paymentNumber == 1){
        //   this.router.navigate(['/'])
        // }
        else {
          localStorage.setItem('companyId', this.companyId + '');
          if (this.paymentNumber == 3) {
            this.htmlForm = res.data;
            this.openInNewTab(res.data);
            // localStorage.setItem('paymentFormHTML',res.data)
            // this.router.navigate(['/payment'])
            // this.htmlForm = this.sanitizer.bypassSecurityTrustHtml(res.data);
            // $('#paymentLinkModal').modal({backdrop: 'static', keyboard: false}, 'show');
            // console.log(this.htmlForm)
          } else {
            this.order.paymentRefNo = res.data.sessionId;
            localStorage.setItem('order', JSON.stringify(this.order));
            this.htmlForm = res.data.form;
            window.location.href = res.data.url;
          }
          // if (this.paymentNumber == 4) this.openInNewTab(res.data.form);
          // else if (this.paymentNumber == 3) this.openInNewTab(res.data);
        }
        // this.router.navigate(['/'])
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translateService.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this._toast.error(errorMessage);
        // this._toast.error('Some thing went wrong');
      }
    });
  }
  closeAddressModal() {
    $('#lastAddressModal').modal('hide');
  }
  closeModal() {
    if (this.paymentMethodText == 'Select your payment method')
      this.paymentMethodText = 'Your payment method is Cach';
    $('#paymentMethodsModal').modal('hide');
  }
  openModal(htmlContent: string) {
    const modalRef = this.modalService.open(ModalContentComponent);
    modalRef.componentInstance.htmlContent = htmlContent;
  }
}
export enum AuthFormType {
  SignIn,
  Register,
  ForgotPassword,
}
