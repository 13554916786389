<h2 class="badge badge-warning" mat-dialog-title>{{ 'YOUR_BASKET' | translate }}</h2>
<div mat-dialog-content>
  <div *ngIf="myOrders.length === 0" class="empty-basket">
    <p>{{ 'EMPTY_BASKET' | translate }}</p>
  </div>
  <div *ngIf="myOrders.length > 0">
    <mat-list>
      <mat-list-item *ngFor="let order of myOrders; let i = index" class="order-item">
        <h4 mat-line class="product-name">{{ order.productName }}</h4>
        <p mat-line class="order-details">
          {{ 'QUANTITY' | translate }}: {{ order.quantity }} | {{ 'TOTAL' | translate }}: {{ order.total | currency }}
        </p>
        <button mat-icon-button color="warn" (click)="removeOrder(i)">
          <i class="fa fa-trash" style="color: red;"></i>
        </button>
      </mat-list-item>
    </mat-list>
    <div class="total">
      <p>{{ 'SubTotal' | translate }}: {{ getTotal() | dynamicCurrency: settingService.getCurrencySymbol() :
        +settingService.getCurrencyPrecision() }}</p>
        <p>{{ 'Delivery Cost' | translate }}: {{ cost | dynamicCurrency: settingService.getCurrencySymbol() :
            +settingService.getCurrencyPrecision() }}</p>
            <p>{{ 'AdditionalServicePrice' | translate }}: {{ getAdditionalService() | dynamicCurrency: settingService.getCurrencySymbol() :
            +settingService.getCurrencyPrecision() }}</p>
        <h6>{{ 'TOTAL' | translate }}: {{ total | dynamicCurrency: settingService.getCurrencySymbol() :
            +settingService.getCurrencyPrecision() }}</h6>
            <p *ngIf="!checkoutLink" (click)="makeOrderBtn()" type="button"
    class="btn btn-warning mx-1 w-100">
    <span class="text-white">{{'Checkout' | translate}} (<span>{{total | dynamicCurrency:
        settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</span>)</span>
  </p>
    </div>
  </div>
</div>
<div class="basket-actions justify-content-center">
  <button *ngIf="myOrders.length > 0" mat-button color="warn" class="btn btn-danger mx-2" (click)="clearOrders()">{{ 'CLEAR_BASKET' | translate }}</button>
  <button mat-button mat-dialog-close class="btn btn-dark">{{ 'CLOSE' | translate }}</button>
</div>
