<div class="modal-header">
    <h5 class="modal-title">{{ 'Select Your Address' | translate }}</h5>
    <button type="button" class="close" (click)="cancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input #autocompleteInput [(ngModel)]="newAddress" type="search" 
         class="form-control form-control-submit my-2" 
         (onAddressChange)="handleAddressChange($event)" 
         ngx-google-places-autocomplete
         placeholder="{{ 'Enter street address or zip code' | translate }}" 
         [options]="options">
    <div #mapContainer id="map" style="height: 400px; width: 100%;"></div>
  </div>
  <div class="modal-footer">
    <!-- Current Location button first with flex order -->
    <button type="button" class="btn btn-danger" (click)="getCurrentLocation()">
      {{'Select Current Location' | translate}}
    </button>
  
    <!-- Other buttons at the end -->
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      {{'Cancel' | translate}}
    </button>
    <button type="button" class="btn btn-success" (click)="saveAndClose()">
      {{'Save Location' | translate}}
    </button>
  </div>
  
  