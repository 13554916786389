import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
// import * as $ from 'jquery';
declare var $: any;

import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Location } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { BasketModalComponent } from 'src/app/pages/basket-modal/basket-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAddressModalComponent } from '../change-address-modal/change-address-modal.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, AfterViewInit {
  loginStatus$ = new BehaviorSubject<boolean>(null);
  userLoginStatus$: Observable<boolean>;
  userName$: Observable<string>;
  langs = {
    en: 'English',
    ar: 'Arabic',
  };
  searchAddress: string = '';
  lat = +localStorage.getItem('lat');
  lng = +localStorage.getItem('lng');
  newLat = +localStorage.getItem('lat');
  newLng = +localStorage.getItem('lng');
  newAddress = localStorage.getItem('address') || '';
  myFavoritesCompanies: any[] = [];
  myFavoritesProducts: any[] = [];
  @Output() SetLatLng = new EventEmitter();
  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  autoComplete: google.maps.places.Autocomplete;

  @Input() address = localStorage.getItem('address') || '';
  updateInputValue(): void {
    const inputElement: HTMLInputElement = this.autocompleteInput.nativeElement;
    inputElement.value = ''; // Clear input value
    setTimeout(() => {
      inputElement.dispatchEvent(new Event('input')); // Trigger input event
    });
  }
  checkoutLink = false;
  address2 = localStorage.getItem('address');
  countries: any[] = [];
  @Output() GetCountryId = new EventEmitter();
  @Output() GetCountry = new EventEmitter();
  @Output() GetAddress = new EventEmitter();
  constructor(
    public _authService: AuthService,
    private _router: Router,
    private modalService: NgbModal,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    public settings: SettingsService,
    private lookupsService: LookupsService,
    private favoriteService: FavoriteService,
    private authService: AuthService,
    private cdref: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private dialog: MatDialog,
    private _location: Location // private authService: SocialAuthService
  ) {
    this.checkoutLink = this._router.url.includes('checkout');
  }
  ngAfterViewInit(): void {
    console.log(this.options);
    console.log(this.authService.getCountryCode());
    this.flagImage =
      localStorage.getItem('flagImage') ||
      'https://rahaldelivery.blob.core.windows.net/static/kuwait.png';
    this.cdref.detectChanges();
  }
  userImg =
    localStorage.getItem('user_image') &&
    localStorage.getItem('user_image') != 'null'
      ? localStorage.getItem('user_image')
      : 'assets/img/profile.png';
  countryName = localStorage.getItem('countryName') || 'الاردن';
  flagImage = '';
  objectArray: any;
  open(content: any, type = '') {
    console.log(type);
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modal-max',
    });
    this.geocoder = new google.maps.Geocoder();
    this.loadGoogleMaps();
  }
  loadGoogleMaps() {
    this.mapsAPILoader.load().then(() => {
      this.initializeMap();
    });
  }
  initializeMap() {
    // const mapProp = {
    //   center: new google.maps.LatLng(
    //     this.lat != 0 ? this.lat : 37.7749,
    //     this.lng != 0 ? this.lng : -122.4194
    //   ),
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // };
    setTimeout(() => {
      console.log('lat');
      console.log(this.lat);
      //  this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(
          this.lat != 0 ? this.lat : 29.2404461,
          this.lng != 0 ? this.lng : 47.9684709
        ), // Default to San Francisco coordinates
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      const mapContainer = document.getElementById('mapContainer');

      this.map = new google.maps.Map(mapContainer, mapOptions);

      this.map.addListener('click', (event: any) => {
        this.placeMarker(event.latLng);
      });
      setTimeout(() => {
        this.updateLocation();
      }, 1000);
      if (!this.address2) this.getCurrentLocation();
    }, 1000);

    setTimeout(() => {
      this.setMapOnAll(null);
    }, 2000);
  }
  updateLocation() {
    const newLocation = new google.maps.LatLng(
      this.newLat != 0 ? this.newLat : this.lat,
      this.newLng != 0 ? this.newLng : this.lng
    );
    this.placeMarker(newLocation);
    this.map.setCenter(newLocation); // Center the map on the new location
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // this.lat = +position.coords.latitude;
        // this.lat = +position.coords.longitude;
        // this.map.setCenter(pos);
        if (this.currentLocationMarker) {
          // If the marker for the current location exists, remove it from the map
          this.currentLocationMarker.setMap(null);
          this.currentLocationMarker = null; // Reset the currentLocationMarker reference
        }
        this.currentLocationMarker = new google.maps.Marker({
          position: pos,
          map: this.map,
          title: 'Your Location',
        });
        // new google.maps.Marker({
        //   position: pos,
        //   map: this.map,
        //   title: 'Your Location',
        // });
      });
    }
  }
  setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  marker: google.maps.Marker;
  currentLocationMarker: google.maps.Marker | null = null; // Initialize marker for current location
  markers: google.maps.Marker[] = []; // Initialize an empty array to store markers
  clearMarkers(map: google.maps.Map | null) {
    this.markers.forEach((marker) => {
      marker.setMap(map); // Clear the marker from the map
    });
    this.markers = []; // Reset the markers array
  }
  addressInfoModal = {
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    buildingNumber: '',
    apartmentNumber: '',
    region: '',
  };
  placeMarker(location: any) {
    this.clearMarkers(null); // Clear all markers before placing a new one
    if (this.marker) {
      // If a marker already exists, update its position
      this.marker.setPosition(location);
    } else {
      // If no marker exists, create a new one
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
      });
    }
    if (this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null); // Remove the current location marker
      this.currentLocationMarker = null; // Reset the currentLocationMarker reference
    }
    this.markers.push(this.marker); // Add the new marker to the array

    this.newLat = location.lat();
    this.newLng = location.lng();
    // this.branchForm.patchValue({
    //   latLng: this.latitude + ',' + this.longitude,
    // });
    console.log(this.lat);
    console.log(this.lng);
    // Display the marker on the map if it's not already visible
    if (!this.marker.getMap()) {
      this.marker.setMap(this.map);
    }
    this.geocoder.geocode(
      { location: location },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.searchAddress = results[0].formatted_address;
            console.log('results[0].formatted_address');
            console.log(results[0].formatted_address);
            this.newAddress = results[0].formatted_address;

            const addressComponents = results[0].address_components;
            let streetName = '';

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('route')) {
                streetName = addressComponents[i].long_name;
                // this.branchForm.patchValue({
                //   street: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('postal_code')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   postalCode: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('street_number')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   buildingNumber: addressComponents[i].long_name,
                // });
                break;
              }
            }

            for (let i = 0; i < addressComponents.length; i++) {
              const types = addressComponents[i].types;
              if (types.includes('subpremise')) {
                console.log(addressComponents[i].long_name);
                // this.branchForm.patchValue({
                //   apartmentNumber: addressComponents[i].long_name,
                // });
                break;
              }
            }
            this.addressInfoModal = {
              streetNumber: '',
              streetName: '',
              city: '',
              state: '',
              country: '',
              postalCode: '',
              buildingNumber: '',
              apartmentNumber: '',
              region: '',
            };

            addressComponents.forEach((component) => {
              const types = component.types;

              if (types.includes('street_number')) {
                this.addressInfoModal.streetNumber = component.long_name;
              } else if (types.includes('route')) {
                this.addressInfoModal.streetName = component.long_name;
              } else if (types.includes('locality')) {
                this.addressInfoModal.city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                this.addressInfoModal.state = component.long_name;
              } else if (types.includes('country')) {
                this.addressInfoModal.country = component.long_name;
              } else if (types.includes('postal_code')) {
                this.addressInfoModal.postalCode = component.long_name;
              } else if (
                types.includes('sublocality') ||
                types.includes('sublocality_level_1')
              ) {
                this.addressInfoModal.region = component.long_name;
              } else if (types.includes('premise')) {
                this.addressInfoModal.buildingNumber = component.long_name;
              } else if (types.includes('subpremise')) {
                this.addressInfoModal.apartmentNumber = component.long_name;
              }
            });

            // for (let i = 0; i < addressComponents.length; i++) {
            //   const component: AddressComponent = addressComponents[i];

            //   if (this.isStreetNumber(component)) {
            //     // this.address.street_number = component.long_name;
            //     console.log(component.long_name)
            //   }

            //   if (this.isStreetName(component)) {
            //     // this.address.street_name = component.long_name;
            //     console.log(component.long_name)

            //   }

            //   if (this.isCity(component)) {
            //     // this.address.city = component.long_name;
            //     console.log("ciii")
            //     console.log(component.long_name)
            //   }

            //   if (this.isCountry(component)) {
            //     // this.address.country = component.long_name;
            //     console.log(component.long_name)

            //   }

            //   if  (this.isState(component)) {
            //     // this.address.state = component.long_name;
            //     console.log(component.long_name)

            //   }

            //   if (this.isPostalCode(component)) {
            //     // this.address.postal_code = component.long_name;
            //     console.log(component.long_name)
            //   }
            // }
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  options: any;
  backClicked() {
    this._location.back();
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    this.newAddress = address.formatted_address;
    this.newLng = address.geometry.location.lng();
    this.newLat = address.geometry.location.lat();
    // localStorage.setItem('newAddress', 'true');
    // localStorage.setItem('address', this.address);
    // localStorage.setItem('lng', this.lng.toString());
    // localStorage.setItem('lat', this.lat.toString());
    this.SetLatLng.emit({ lat: this.lat, lng: this.lng });
    // this.modalService.dismissAll();
    this.updateLocation();
    const addressComponents = address.address_components;
    this.addressInfoModal = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      buildingNumber: '',
      apartmentNumber: '',
      region: '',
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        this.addressInfoModal.streetNumber = component.long_name;
      } else if (types.includes('route')) {
        this.addressInfoModal.streetName = component.long_name;
      } else if (types.includes('locality')) {
        this.addressInfoModal.city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        this.addressInfoModal.state = component.long_name;
      } else if (types.includes('country')) {
        this.addressInfoModal.country = component.long_name;
      } else if (types.includes('postal_code')) {
        this.addressInfoModal.postalCode = component.long_name;
      } else if (
        types.includes('sublocality') ||
        types.includes('sublocality_level_1')
      ) {
        this.addressInfoModal.region = component.long_name;
      } else if (types.includes('premise')) {
        this.addressInfoModal.buildingNumber = component.long_name;
      } else if (types.includes('subpremise')) {
        this.addressInfoModal.apartmentNumber = component.long_name;
      }
    });
  }

  checkUrl = true;
  check = false;
  // initAutocomplete(): void {
  //   // Check if searchInputRef is defined before accessing its nativeElement
  //   this.autoComplete = new google.maps.places.Autocomplete(
  //     this.autocompleteInput.nativeElement,
  //     {
  //       componentRestrictions: {
  //         country: this.authService.getCountryCode() || 'kw',
  //       }, // Default country
  //     }
  //   );
  //   this.autoComplete.addListener(
  //     'place_changed',
  //     this.handlePlaceChanged.bind(this)
  //   );
  //   this.autoComplete.setOptions({
  //     componentRestrictions: { country: this.authService.getCountryCode() },
  //   });
  // }
  @Output() updateOrders = new EventEmitter<any[]>(); // Emit orders to parent

  openBasketModal(): void {
    const dialogRef = this.dialog.open(BasketModalComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      const myOrders = JSON.parse(localStorage.getItem('my_orders') || '[]');
      this.updateOrders.emit(myOrders);
    });
  }
  isMobile: boolean = false;
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkIfMobile.bind(this));
  }
  checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
  }
  ngOnInit(): void {
    this.checkIfMobile();
  window.addEventListener('resize', this.checkIfMobile.bind(this));
    this.userLoginStatus$ = this._authService.isUserLoggedIn;
    this.userName$ = this._authService.currentUserName;
    this.objectArray = [
      { id: 1, name: 'Angular' },
      { id: 2, name: 'Typescript' },
      { id: 3, name: 'Javascript' },
      { id: 4, name: 'HTML' },
      { id: 5, name: 'Java' },
      { id: 6, name: 'dotnet' },
    ];
    this.options = {
      componentRestrictions: {
        country: this.authService.getCountryCode() || 'kw',
      },
    };
    this.getCountries();
    this.checkUrl = this._router.url == '/';
    setTimeout(() => {
      this.autoComplete = new google.maps.places.Autocomplete(
        this.autocompleteInput.nativeElement,
        {
          componentRestrictions: {
            country: this.authService.getCountryCode() || 'kw',
          }, // Default country
        }
      );
      this.autoComplete.addListener(
        'place_changed',
        this.handlePlaceChanged.bind(this)
      );
    }, 1000);
    // this.initAutocomplete()
  }
  @ViewChild('changeAddress') changeAddressModal: TemplateRef<any>;
  modalRef: NgbModalRef;
  openChangeAddressModal() {
    this.modalRef = this.modalService.open(this.changeAddressModal, {
      size: 'lg',
    }); // Adjust size as needed
  }
  navChanged(event) {
    this.getFavorites(event.nextId);
    // window.scrollTo({ top: 0 });
    // this.nav_number = event.nextId;
    // this.pageIndex = 1;
    // if (event.nextId == 1) this.globalFilter(1);
    // else this.globalFilter(2);
  }
  getFav() {
    this.getFavorites(1);
  }
  getFavorites(type) {
    this.myFavoritesCompanies = [];
    this.myFavoritesProducts = [];
    if (this._authService.getShopTypeId()) {
      if (type == 1) {
        this.favoriteService
          .myFavoriteCompanies(this._authService.getShopTypeId())
          .subscribe((res) => {
            console.log(this.myFavoritesCompanies);
            this.myFavoritesCompanies = res.data;
            console.log(this.myFavoritesCompanies);
          });
      } else {
        this.favoriteService
          .myFavoriteProducts(this._authService.getShopTypeId())
          .subscribe((res) => {
            this.myFavoritesProducts = res.data;
          });
      }
    }
  }
  hideFavouriteModal(i, f) {
    $('#myFavoriteModal').modal('hide');
    console.log(window.location.href);
    // if(window.location.href.includes('/company?')){
    //   this._router.navigate(['company'], {
    //     queryParams: { id: i, isFavorite: f },
    //   });
    //   window.location.reload();
    // }
    // this._router.navigate(['company'], {
    //   queryParams: { id: i, isFavorite: f },
    // });
    // Construct the URL with the updated query parameters
    const url = `/company?id=${i}&isFavorite=${f}`;

    // Open the URL in a new browser window
    window.open(url, '_blank');
  }
  goToCompanies() {
    $('#myFavoriteModal').modal('hide');
    this._router.navigate(['companies'], {
      queryParams: { id: this._authService.getShopTypeId() },
    });
  }
  toggleFavorite(id, type) {
    console.log(id);
    this.RemoveElementFromArray(id, type);
  }
  RemoveElementFromArray(key: number, type) {
    if (type == 0) {
      this.favoriteService
        .addCompanyToFavorites(key, false)
        .subscribe((res) => {
          if (res.isSuccess)
            this.myFavoritesCompanies = this.myFavoritesCompanies.filter(
              (item) => item.id !== key
            );
        });
    } else {
      this.favoriteService
        .addProductToFavorites(key, false)
        .subscribe((res) => {
          if (res.isSuccess)
            this.myFavoritesProducts = this.myFavoritesProducts.filter(
              (item) => item.id !== key
            );
        });
    }

    // this.myFavoritesProducts.forEach((value, index) => {
    //   if (value.id == key) this.myFavoritesProducts.splice(index, 1);
    // });
    // console.log(this.objectArray);
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe((res) => {
      console.log(this.countries);
      this.countries = res.data;
      // if (!localStorage.getItem('countryId') || !localStorage.getItem('flagImage') || !localStorage.getItem('countryCode')) {
      //   localStorage.setItem('countryId', res.data[0].id);
      //   localStorage.setItem('flagImage', res.data[0].flagImage);
      //   localStorage.setItem('countryCode', res.data[0].countryCode);
      // }
      // if (!localStorage.getItem('currencySymbol')) {
      //   localStorage.setItem('currencyCode', res.data[0].currencyCode);
      //   localStorage.setItem('currencyName', res.data[0].currencyName);
      //   localStorage.setItem(
      //     'currencyPrecision',
      //     res.data[0].currencyPrecision
      //   );
      //   localStorage.setItem('currencySymbol', res.data[0].currencySymbol);
      //   localStorage.setItem('currencySymbolAr', res.data[0].currencySymbolAr);
      //   localStorage.setItem('currencySymbolEn', res.data[0].currencySymbolEn);
      // }
    });
  }
  openChangeAddress() {
    const currentCountryId = +localStorage.getItem('countryId');
    let country = this.countries.find((c) => c.id == currentCountryId);
    if (country) this.changeCountry(country, 1);
  }
  changeCountry(country, type = 0) {
    const currentCountryId = +localStorage.getItem('countryId');
    // console.log(currentCountryId)
    // console.log(country)
    // return
    $('.close').trigger('click');
    if (country.id != currentCountryId) {
      // Clear the localStorage items related to the previous address
      localStorage.removeItem('address');
      localStorage.removeItem('lng');
      localStorage.removeItem('lat');
      localStorage.removeItem('streetNumber');
      localStorage.removeItem('streetName');
      localStorage.removeItem('city');
      localStorage.removeItem('state');
      localStorage.removeItem('country');
      localStorage.removeItem('postalCode');
      localStorage.removeItem('buildingNumber');
      localStorage.removeItem('apartmentNumber');
      localStorage.removeItem('region');
      localStorage.setItem('countryId', country.id);
      localStorage.setItem('flagImage', country.flagImage);
      localStorage.setItem('countryCode', country.countryCode);
      localStorage.setItem('currencyName', country.currencyName);
      localStorage.setItem('currencyPrecision', country.currencyPrecision);
      localStorage.setItem('currencySymbol', country.currencySymbol);
      localStorage.setItem('currencySymbolAr', country.currencySymbolAr);
      localStorage.setItem('currencySymbolEn', country.currencySymbolEn);
      let arr = country.latLng.split(',');
      this.lat = +arr[0];
      this.lng = +arr[1];
      localStorage.setItem('lat', this.lat + '');
      localStorage.setItem('lng', this.lng + '');
      // const myOrders = JSON.parse('[]');
      // this.updateOrders.emit(myOrders);
      localStorage.setItem('my_orders', '');
      if (this._router.url != '/') {
        this._router.navigate(['/']);
        return;
      }
    }
    // if (type != 1) {
    //   localStorage.setItem('countryId', country.id);
    //   localStorage.setItem('flagImage', country.flagImage);
    //   localStorage.setItem('countryCode', country.countryCode);
    //   localStorage.setItem('currencyName', country.currencyName);
    //   localStorage.setItem('currencyPrecision', country.currencyPrecision);
    //   localStorage.setItem('currencySymbol', country.currencySymbol);
    //   localStorage.setItem('currencySymbolAr', country.currencySymbolAr);
    //   localStorage.setItem('currencySymbolEn', country.currencySymbolEn);
    //   let arr = country.latLng.split(',');
    //   this.lat = +arr[0];
    //   this.lng = +arr[1];
    //   localStorage.setItem('lat', this.lat + '');
    //   localStorage.setItem('lng', this.lng + '');
    //   return
    //   $('.close').trigger('click');
    // }
    this.GetCountryId.emit(country.id);
    this.GetCountry.emit(country);

    const modalRef = this.modalService.open(ChangeAddressModalComponent, {
      size: 'lg', // Adjust size as needed ('sm', 'lg', 'xl')
      backdrop: 'static', // Optional: Prevent modal from closing when clicking outside
      keyboard: true, // Optional: Allow closing with the escape key
    });
    // Pass input to the modal

    modalRef.componentInstance.countryId = country.id;
    if (type == 1) {
      let lat = localStorage.getItem('lat');
      let lng = localStorage.getItem('lng');
      modalRef.componentInstance.latLng = lat + ', ' + lng;
      modalRef.componentInstance.newAddress =
        localStorage.getItem('address') || '';
    } else {
      modalRef.componentInstance.newAddress = '';
      this.address = '';
      modalRef.componentInstance.latLng = country.latLng;
    }

    modalRef.componentInstance.countryCode = country.countryCode;
    // Handle data after modal close if needed
    modalRef.result.then(
      (result) => {
        localStorage.setItem('my_orders', '');
      if (this._router.url != '/') {
        this._router.navigate(['/']);
        return;
      }
        this.address = result.newAddress;
        this.SetLatLng.emit({ lat: this.lat, lng: this.lng });
        // console.log('Modal closed with:', result);
      },
      (reason) => {
        this.address = reason.newAddress;
        // console.log('Modal dismissed:', reason);
      }
    );
  }

  selectAddressFromMap() {
    if (this.newLat != 0) {
      this.lat = this.newLat;
      this.lng = this.newLng;
      this.address = this.newAddress;
      localStorage.setItem('newAddress', 'true');
      localStorage.setItem('address', this.address);
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
      this.modalService.dismissAll();
      this.newLat = 0;
      this.newLng = 0;
      this.newAddress = '';
      localStorage.setItem('streetNumber', this.addressInfoModal.streetNumber);
      localStorage.setItem('streetName', this.addressInfoModal.streetName);
      localStorage.setItem('city', this.addressInfoModal.city);
      localStorage.setItem('state', this.addressInfoModal.state);
      localStorage.setItem('country', this.addressInfoModal.country);
      localStorage.setItem('postalCode', this.addressInfoModal.postalCode);
      localStorage.setItem(
        'buildingNumber',
        this.addressInfoModal.buildingNumber
      );
      localStorage.setItem(
        'apartmentNumber',
        this.addressInfoModal.apartmentNumber
      );
      localStorage.setItem('region', this.addressInfoModal.region);
      const addressInfo = {
        address: this.address,
        lng: parseFloat(this.lng.toString()),
        lat: parseFloat(this.lat.toString()),
        streetNumber: this.addressInfoModal.streetNumber,
        streetName: this.addressInfoModal.streetName,
        city: this.addressInfoModal.city,
        state: this.addressInfoModal.state,
        country: this.addressInfoModal.country,
        postalCode: this.addressInfoModal.postalCode,
        buildingNumber: this.addressInfoModal.buildingNumber,
        apartmentNumber: this.addressInfoModal.apartmentNumber,
        region: this.addressInfoModal.region,
      };
      const addressData = {
        apartmentNo: addressInfo.apartmentNumber,
        buildingNo: addressInfo.buildingNumber,
        city: addressInfo.city,
        country: addressInfo.country,
        latLng: `${this.lat},${this.lng}`,
        street: `${addressInfo.streetNumber} ${addressInfo.streetName}`,
        region: addressInfo.region,
        phoneNumber: '', // Add phoneNumber if available
        saveAddressAs: '', // Add saveAddressAs if needed
        isSaveAddress: true,
      };
      localStorage.setItem('addressData', JSON.stringify(addressData));

      this.GetAddress.emit(addressInfo);
    }
  }
  isStreetNumber(component: AddressComponent): boolean {
    return component.types.includes('street_number');
  }

  isStreetName(component: AddressComponent): boolean {
    return component.types.includes('route');
  }

  isCity(component): boolean {
    return component.types.includes('locality');
  }

  isState(component): boolean {
    return component.types.includes('administrative_area_level_1');
  }

  isCountry(component): boolean {
    return component.types.includes('country');
  }

  isPostalCode(component): boolean {
    return component.types.includes('postal_code');
  }

  handlePlaceChanged(): void {
    const place = this.autoComplete.getPlace();
    if (place && place.formatted_address) {
      this.handleAddressChange(place);
    }
  }
  signOut() {
    // if (localStorage.getItem('provider')) this.authService.signOut();
    this._authService.logout();
    this._router.navigate(['login']);
  }
  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str); //!! return boolean
  }
  openmin(content: any) {
    this.modalService.open(content, {
      animation: true,
      size: 'xs',
      windowClass: 'modal-class',
    });
  }
  current_lang = localStorage.getItem('current_lang') || 'en';

  useLanguage(language: string) {
    // console.log(language)
    this.translate.use(language); //key
    this.settings.setLanguage(language);
  }
  changeLang(key) {
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = key === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = key;
    // console.log(event.target.value);
    this.translate.use(key);
    this.settings.setLanguage(key);
    this.settings.updateLang(key);
    localStorage.setItem('current_lang', key);
    if (key == 'ar') this.loadStyle('style-ar.css');
    else this.loadStyle('style.css');
    window.location.reload();
    this.settings.updateLang(key);
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `assets/css/${styleName}`;
      head.appendChild(style);
    }
  }
}
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}
interface Address {
  street_number?: string;
  street_name?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
}
