import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-change-address-modal',
  templateUrl: './change-address-modal.component.html',
  styleUrls: ['./change-address-modal.component.css'],
})
export class ChangeAddressModalComponent implements AfterViewInit, OnInit {
  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
  @Input() countryId!: number; // Receive countryId as input
  @Input() latLng: string; // Input for latLng
  @Input() countryCode: string; // Input for latLng
  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  autoComplete: google.maps.places.Autocomplete;
  lat = 0;
  lng = 0;
  addressInfoModal: any = {
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    buildingNumber: '', // Adjust if applicable
    apartmentNumber: '', // Adjust if applicable
    region: '',
  };

  map: google.maps.Map;
  currentLocationMarker: google.maps.Marker | null = null;
  @Input() newAddress: string;
  options: any;
  constructor(
    public activeModal: NgbActiveModal,
    private toast: ToastrService,
    private translate: TranslateService
  ) {}
  ngAfterViewInit() {
    console.log(this.countryId);

    // this.initMap();
    this.initializeMap();
  }
  ngOnInit() {
    this.options = {
      componentRestrictions: { country: this.countryCode },
      // fields: ['address_components', 'geometry', 'icon', 'name'],
      // types: ['address'] // Restrict the types to addresses only
    };
  }
  initializeMap() {
    // Check if `latLng` is provided; default to a specific location if not
    const [lat, lng] = this.latLng
      ? this.latLng.split(',').map(Number)
      : [31.95453, 35.910042];
    const position = { lat, lng };

    // Create the map
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: position,
        zoom: 15,
      }
    );

    // Add a marker at the provided `latLng`
    this.currentLocationMarker = new google.maps.Marker({
      position,
      map: this.map,
      title: 'Selected Location',
    });
    // Reverse geocode to get initial address components
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ location: position }, (results, status) => {
    if (status === 'OK' && results[0]) {
      const address = results[0];
      const addressComponents = address.address_components;

      // Extract and set address information
      this.addressInfoModal = {
        streetNumber: this.getAddressComponent(
          addressComponents,
          'street_number'
        ),
        streetName: this.getAddressComponent(addressComponents, 'route'),
        city: this.getAddressComponent(addressComponents, 'locality'),
        state: this.getAddressComponent(
          addressComponents,
          'administrative_area_level_1'
        ),
        country: this.getAddressComponent(addressComponents, 'country'),
        postalCode: this.getAddressComponent(
          addressComponents,
          'postal_code'
        ),
        buildingNumber: '', // Adjust as needed
        apartmentNumber: '', // Adjust as needed
        region:
          this.getAddressComponent(addressComponents, 'sublocality') ||
          this.getAddressComponent(addressComponents, 'neighborhood'),
      };

      // Save latitude and longitude
      this.lat = lat;
      this.lng = lng;
      this.newAddress = address.formatted_address || '';
    } else {
      console.error(
        'Initial geocode was not successful for the following reason: ' + status
      );
    }
  });
    // Add click event listener to the map
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      const clickedPosition = event.latLng;
      if (clickedPosition) {
        // Update the marker position
        if (this.currentLocationMarker) {
          this.currentLocationMarker.setPosition(clickedPosition);
        } else {
          this.currentLocationMarker = new google.maps.Marker({
            position: clickedPosition,
            map: this.map,
            title: 'Selected Location',
          });
        }

        // Optionally, update `newAddress` or any other properties if needed
        this.map.setCenter(clickedPosition);

        // Reverse geocode to get address components
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: clickedPosition }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const address = results[0];
            const addressComponents = address.address_components;

            // Extract and set address information
            this.addressInfoModal = {
              streetNumber: this.getAddressComponent(
                addressComponents,
                'street_number'
              ),
              streetName: this.getAddressComponent(addressComponents, 'route'),
              city: this.getAddressComponent(addressComponents, 'locality'),
              state: this.getAddressComponent(
                addressComponents,
                'administrative_area_level_1'
              ),
              country: this.getAddressComponent(addressComponents, 'country'),
              postalCode: this.getAddressComponent(
                addressComponents,
                'postal_code'
              ),
              buildingNumber: '', // Adjust as needed
              apartmentNumber: '', // Adjust as needed
              region:
                this.getAddressComponent(addressComponents, 'sublocality') ||
                this.getAddressComponent(addressComponents, 'neighborhood'),
            };

            // Save latitude and longitude
            this.lat = clickedPosition.lat();
            this.lng = clickedPosition.lng();
            this.newAddress = address.formatted_address || '';
          } else {
            console.error(
              'Geocode was not successful for the following reason: ' + status
            );
          }
        });
      }
    });
  }

  initMap() {
    const initialPosition = { lat: 0, lng: 0 }; // Change to a default position if desired

    this.map = new google.maps.Map(this.mapContainer.nativeElement, {
      center: initialPosition,
      zoom: 15,
    });

    // Optional: Add a marker at the initial position
    this.currentLocationMarker = new google.maps.Marker({
      position: initialPosition,
      map: this.map,
      title: 'Initial Position',
    });
  }
  handleAddressChange(address: google.maps.places.PlaceResult) {
    // Extract latitude and longitude from the address geometry
    const location = address.geometry.location;
    const newPosition = {
      lat: location.lat(),
      lng: location.lng(),
    };

    // Update the map center and marker position
    this.map.setCenter(newPosition);
    if (this.currentLocationMarker) {
      this.currentLocationMarker.setPosition(newPosition);
    } else {
      this.currentLocationMarker = new google.maps.Marker({
        position: newPosition,
        map: this.map,
        title: 'Selected Location',
      });
    }
    // Extract address components
    const addressComponents = address.address_components;
    this.addressInfoModal = {
      streetNumber: this.getAddressComponent(
        addressComponents,
        'street_number'
      ),
      streetName: this.getAddressComponent(addressComponents, 'route'),
      city: this.getAddressComponent(addressComponents, 'locality'),
      state: this.getAddressComponent(
        addressComponents,
        'administrative_area_level_1'
      ),
      country: this.getAddressComponent(addressComponents, 'country'),
      postalCode: this.getAddressComponent(addressComponents, 'postal_code'),
      buildingNumber: '', // Extract if applicable
      apartmentNumber: '', // Extract if applicable
      region:
        this.getAddressComponent(addressComponents, 'sublocality') ||
        this.getAddressComponent(addressComponents, 'neighborhood'),
    };
    // Save latitude and longitude
    this.lat = newPosition.lat;
    this.lng = newPosition.lng;
    this.newAddress = address.formatted_address || '';
    console.log(this.addressInfoModal);
  }
  getAddressComponent(
    components: google.maps.GeocoderAddressComponent[],
    type: string
  ): string {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : '';
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        // Set map center to the current location
        this.map.setCenter(pos);

        // Place or move the marker at the current location
        if (this.currentLocationMarker) {
          this.currentLocationMarker.setPosition(pos);
        } else {
          this.currentLocationMarker = new google.maps.Marker({
            position: pos,
            map: this.map,
            title: 'Your Location',
          });
        }
        // Reverse geocode to get address components for the current position
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: pos }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const address = results[0];
            const addressComponents = address.address_components;

            // Extract and set address information
            this.addressInfoModal = {
              streetNumber: this.getAddressComponent(
                addressComponents,
                'street_number'
              ),
              streetName: this.getAddressComponent(addressComponents, 'route'),
              city: this.getAddressComponent(addressComponents, 'locality'),
              state: this.getAddressComponent(
                addressComponents,
                'administrative_area_level_1'
              ),
              country: this.getAddressComponent(addressComponents, 'country'),
              postalCode: this.getAddressComponent(
                addressComponents,
                'postal_code'
              ),
              buildingNumber: '', // Adjust if applicable
              apartmentNumber: '', // Adjust if applicable
              region:
                this.getAddressComponent(addressComponents, 'sublocality') ||
                this.getAddressComponent(addressComponents, 'neighborhood'),
            };

            // Save latitude, longitude, and address information in localStorage
            this.lat = pos.lat;
            this.lng = pos.lng;
            this.newAddress = address.formatted_address || '';
          } else {
            console.error(
              'Geocode was not successful for the following reason: ' + status
            );
          }
        });
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  // Method to close the modal and pass data back to the parent
  saveAndClose() {
    if (!this.newAddress) {
      this.toast.warning(this.translate.instant('Please Select Your Location'));
      return
    }
    const result = { newAddress: this.newAddress }; // Replace with actual data

    // Save address information to localStorage
    localStorage.setItem('address', this.newAddress || ''); // Save the formatted address
    localStorage.setItem('lng', this.lng.toString());
    localStorage.setItem('lat', this.lat.toString());

    // Save detailed address components
    localStorage.setItem(
      'streetNumber',
      this.addressInfoModal.streetNumber || ''
    );
    localStorage.setItem('streetName', this.addressInfoModal.streetName || '');
    localStorage.setItem('city', this.addressInfoModal.city || '');
    localStorage.setItem('state', this.addressInfoModal.state || '');
    localStorage.setItem('country', this.addressInfoModal.country || '');
    localStorage.setItem('postalCode', this.addressInfoModal.postalCode || '');
    localStorage.setItem(
      'buildingNumber',
      this.addressInfoModal.buildingNumber || ''
    );
    localStorage.setItem(
      'apartmentNumber',
      this.addressInfoModal.apartmentNumber || ''
    );
    localStorage.setItem('region', this.addressInfoModal.region || '');

    const addressData = {
      apartmentNo: this.addressInfoModal.apartmentNumber,
      buildingNo: this.addressInfoModal.buildingNumber,
      city: this.addressInfoModal.city,
      country: this.addressInfoModal.country,
      latLng: `${this.lat},${this.lng}`,
      street: `${this.addressInfoModal.streetNumber} ${this.addressInfoModal.streetName}`,
      region: this.addressInfoModal.region,
      phoneNumber: '', // Add phoneNumber if available
      saveAddressAs: '', // Add saveAddressAs if needed
      isSaveAddress: true,
    };
    localStorage.setItem('addressData', JSON.stringify(addressData));

    this.activeModal.close(result); // Passes data to the modal result handler in the parent
  }

  // Method to dismiss the modal without returning data
  cancel() {
    const result = { newAddress: localStorage.getItem('address') || '' }; // Replace with actual data
    this.activeModal.dismiss(result); // Optional reason for dismissal
  }
  closeModal() {
    // Logic to close the modal
  }
}
