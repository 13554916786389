<!-- Navigation -->
<div class="header">
  <header class="full-width">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mainNavCol">
          <!-- logo -->
          <div class="logo mainNavCol">
            <a *ngIf="!checkUrl" (click)="backClicked()" class="go-back-arrow">
              <i class="fas" [ngClass]="current_lang == 'en' ? 'fa-angle-left' : 'fa-angle-right'"></i> <!-- Arrow icon -->
            </a>
            <a routerLink="/">
              <img src="assets/img/logo/01-Delivery----grey.png" class="img-fluid logo-new-style" alt="Logo">
            </a>
          </div>
          <!-- logo -->
          <div class="main-search mainNavCol">
            <form class="main-search search-form full-width">
              <div class="row justify-content-center">
                <!-- location picker -->
                <div *ngIf="!isMobile && address && !checkoutLink" class="col-lg-4" (click)="openChangeAddress()">
                  <a href="javascript:void(0)" class="delivery-add p-relative" title="{{address}}"> <span class="icon"><i
                        class="fas fa-map-marker-alt"></i></span>
                    <span class="address">{{ address | slice:0:20 }}.....</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div class="right-side fw-700 mainNavCol">
            <a title="{{'My Basket' | translate}}" *ngIf="flagImage" href="javascript:void(0)" (click)="openBasketModal()"
            class="text-light-white fw-500 mx-1">
            <img style="height: 30px;width: 30px;" src="/assets/img/shopping-basket2.png" class="rounded-circle" alt="userimg">
          </a>
           <!-- Location Icon -->
  <a *ngIf="isMobile" title="{{'Change Location' | translate}}" href="javascript:void(0)" (click)="openChangeAddress()"
  class="text-light-white fw-500 mx-1">
  <img style="height: 30px;width: 30px;" src="/assets/img/location.png" class="rounded-circle" alt="userimg">
</a>
            <a *ngIf="flagImage" data-toggle="modal" data-target="#staticBackdrop" href="javascript:void(0)"
              class="text-light-white fw-500 lang-country-selector">
              <img style="height: 30px;width: 30px;" [src]="flagImage" class="rounded-circle" alt="userimg">
            </a>
           
            <div class="mobile-search">
              <a href="#" data-toggle="modal" data-target="#search-box"> <i class="fas fa-search"></i>
              </a>
            </div>
            <div class="user-details p-relative">
              <a *ngIf="_authService.checkUserIsLogedIn()" href="javascript:void(0)" class="text-light-white fw-500">
                <img [src]="userImg" class="rounded-circle" alt="userimg"> <span>{{'Hi' | translate}},
                  {{_authService.getUserName() | titlecase}}</span>
              </a>
              <div class="btn-auth" *ngIf="!_authService.checkUserIsLogedIn()">
                <a class="btn btn-register" routerLink="/register">{{'Register' | translate}}</a>
                <a class="btn btn-login" routerLink="/login">{{'Login' | translate}}</a>
              </div>
              <div *ngIf="_authService.checkUserIsLogedIn()" class="user-dropdown">
                <ul>
                  <li>
                    <a class="favorite-btn" routerLink="/my-orders">
                      <div class="icon"><i class="flaticon-rewind"></i>
                      </div> <span class="details">{{'Past Orders' | translate}}</span>
                    </a>
                  </li>
                  <!-- <li>
                    <a routerLink="/orderdetails">
                      <div class="icon"><i class="flaticon-takeaway"></i>
                      </div> <span class="details">Upcoming Orders</span>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a href="javascript:void(0)">
                      <div class="icon"><i class="flaticon-breadbox"></i>
                      </div> <span class="details">Saved</span>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a href="javascript:void(0)">
                      <div class="icon"><i class="flaticon-gift"></i>
                      </div> <span class="details">Gift cards</span>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a href="javascript:void(0)">
                      <div class="icon"><i class="flaticon-refer"></i>
                      </div> <span class="details">Refer a friend</span>
                    </a>
                  </li> -->

                  <li>
                    <a routerLink="/my-profile">
                      <div class="icon"><i class="flaticon-user"></i>
                      </div> <span class="details">{{'Account' | translate}}</span>
                    </a>
                  </li>
                  <li>
                    <a class="favorite-btn" (click)="openBasketModal()">
                      <div class="icon"><i class="flaticon-takeaway"></i>
                      </div> <span class="details">{{'My Basket' | translate}}</span>
                    </a>
                  </li>
                  <li>
                    <a class="favorite-btn" (click)="getFav()" data-toggle="modal" data-target="#myFavoriteModal">
                      <div class="icon"><i class="flaticon-heart-1"></i>
                      </div> <span class="details">{{'Favorites' | translate}}</span>
                    </a>
                  </li>
                  <!-- <li>
                    <a href="javascript:void(0)">
                      <div class="icon"><i class="flaticon-board-games-with-roles"></i>
                      </div> <span class="details">Help</span>
                    </a>
                  </li> -->
                </ul>
                <div class="user-footer"><a (click)="signOut()" style="cursor: pointer;color: #ff0018;">{{'Sign Out' |
                    translate}}</a>
                </div>
              </div>
            </div>
            <!-- mobile search -->
            <!-- user notification -->
            <!-- <div class="cart-btn notification-btn">
                                <a href="javascript:void(0)" class="text-light-green fw-700"> <i class="fas fa-bell"></i>
                                    <span class="user-alert-notification"></span>
                                </a>
                                <div class="notification-dropdown">
                                    <div class="product-detail">
                                        <a href="javascript:void(0)">
                                            <div class="img-box">
                                                <img src="assets/img/shop-1.png" class="rounded" alt="image">
                                            </div>
                                            <div class="product-about">
                                                <p class="text-light-black">Lil Johnny’s</p>
                                                <p class="text-light-white">Spicy Maxican Grill</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="rating-box">
                                        <p class="text-light-black">How was your last order ?.</p> <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                        <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                        <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                        <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                        <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                        <cite class="text-light-white">Ordered 2 days ago</cite>
                                    </div>
                                </div>
                            </div> -->
            <!-- user notification -->
            <!-- user cart -->
            <!-- <div class="cart-btn cart-dropdown">
                                <a href="javascript:void(0)" class="text-light-green fw-700"> <i class="fas fa-shopping-bag"></i>
                                    <span class="user-alert-cart">3</span>
                                </a>
                                <div class="cart-detail-box">
                                    <div class="card">
                                        <div class="card-header padding-15">Your Order</div>
                                        <div class="card-body no-padding">
                                            <div class="cat-product-box">
                                                <div class="cat-product">
                                                    <div class="cat-name">
                                                        <a href="javascript:void(0)">
                                                            <p class="text-light-green"><span class="text-dark-white">1</span> Chilli Chicken</p> <span class="text-light-white">small, chilli chicken</span>
                                                        </a>
                                                    </div>
                                                    <div class="delete-btn">
                                                        <a href="javascript:void(0)" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                    <div class="price"> <a href="javascript:void(0)" class="text-dark-white fw-500">
                              $2.25
                            </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cat-product-box">
                                                <div class="cat-product">
                                                    <div class="cat-name">
                                                        <a href="javascript:void(0)">
                                                            <p class="text-light-green"><span class="text-dark-white">1</span> loaded cheese</p> <span class="text-light-white">small, chilli chicken</span>
                                                        </a>
                                                    </div>
                                                    <div class="delete-btn">
                                                        <a href="javascript:void(0)" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                    <div class="price"> <a href="javascript:void(0)" class="text-dark-white fw-500">
                              $2.25
                            </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cat-product-box">
                                                <div class="cat-product">
                                                    <div class="cat-name">
                                                        <a href="javascript:void(0)">
                                                            <p class="text-light-green"><span class="text-dark-white">1</span> Tortia Chicken</p> <span class="text-light-white">small, chilli chicken</span>
                                                        </a>
                                                    </div>
                                                    <div class="delete-btn">
                                                        <a href="javascript:void(0)" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                    <div class="price"> <a href="javascript:void(0)" class="text-dark-white fw-500">
                              $2.25
                            </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item-total">
                                                <div class="total-price border-0"> <span class="text-dark-white fw-700">Items subtotal:</span>
                                                    <span class="text-dark-white fw-700">$9.99</span>
                                                </div>
                                                <div class="empty-bag padding-15"> <a href="javascript:void(0)">Empty bag</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer padding-15"> <a routerLink="/checkout" class="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
            <!-- user cart -->
          </div>
        </div>
        <div class="col-sm-12 mobile-search">
          <div class="mobile-address">
            <a href="javascript:void(0)" class="delivery-add" data-toggle="modal" data-target="#address-box"> <span
                class="address">{{address?.slice(12)}}</span>
            </a>
          </div>
          <div class="sorting-addressbox"> <span class="full-address text-light-green">{{address}}</span>
            <div class="btns">
              <div class="filter-btn">
                <button type="button"><i class="fas fa-sliders-h text-light-green fs-18"></i>
                </button> <span class="text-light-green">Sort</span>
              </div>
              <div class="filter-btn">
                <button type="button"><i class="fas fa-filter text-light-green fs-18"></i>
                </button> <span class="text-light-green">Filter</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
<div class="main-sec"></div>
<input #autocompleteInput type="hidden" class="form-control form-control-submit"
       ngx-google-places-autocomplete [options]="options"
       (onAddressChange)="handleAddressChange($event)"
       placeholder="{{'Enter street address or zip code' | translate }}">
<!-- Navigation -->
<ng-template #modalcountry let-modal>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h4>اختر اللغة</h4>
        <div class="btn-group" role="group" aria-label="اختيار اللغة">
          <button type="button" class="btn btn-outline-primary" data-lang="english">الإنجليزية</button>
          <button type="button" class="btn btn-outline-primary" data-lang="arabic">العربية</button>
        </div>
      </div>
      <div class="col-md-6">
        <h4>اختر البلد</h4>
        <div class="btn-group" role="group" aria-label="اختيار البلد">
          <button type="button" class="btn btn-outline-primary" data-country="usa">الولايات المتحدة</button>
          <button type="button" class="btn btn-outline-primary" data-country="canada">كندا</button>
          <button type="button" class="btn btn-outline-primary" data-country="uk">المملكة المتحدة</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changeAddress let-modal>
  <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-share bg-primary text-white"></i>
      {{'Change your address' | translate}}    
      </h3>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
              aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
      <!-- <form method="post">
          <div class="ms-form-group has-icon">
              <label>{{'New Address' | translate}}</label>
              <input type="text" placeholder="Email Address" class="form-control" name="news-letter" value="">
              <i class="material-icons">email</i>
          </div>
      </form> -->
      <div class="input-group2 col-xl-12 my-2">
        <p class="text-style-right">{{'New Address' | translate}}</p>
        <!-- <input type="search" class="form-control form-control-submit" ngx-google-places-autocomplete
          [options]="options" (onAddressChange)="handleAddressChange($event)"
          placeholder="Enter street address or zip code" [value]="address ? address : address2"> -->
          <input [(ngModel)]="newAddress" type="search" class="form-control form-control-submit" (onAddressChange)="handleAddressChange($event)"  ngx-google-places-autocomplete
                    placeholder="{{'Enter street address or zip code' | translate}}" [options]="options">
        <!-- <div class="input-group-prepend">
          <button class="input-group-text text-light-green"><i class="fab fa-telegram-plane"></i>
          </button>
        </div> -->
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div id="mapContainer" style="height: 400px; width: 100%;"></div>
        </div>
      </div>
      <div class="form-group mt-3">
        <button type="button" class="btn-second btn-submit full-width" (click)="selectAddressFromMap()">{{'Select Address' | translate}}</button>
      </div>
  </div>
  <!-- <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-primary shadow-none">Submit</button>
  </div> -->
</ng-template>
<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true" style="z-index: 10000000000;">
  <div class="modal-dialog modal-dialog-nav modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Countries' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <ng-container *ngIf="countries.length == 0">
              <div class="gem-points p-2" *ngFor="let i of [0,1,2]">
                <ejs-skeleton shape="Circle" width="60px"></ejs-skeleton>
              </div>
              <!-- <ngx-skeleton-loader *ngFor="let i of [0,1,2,3]" class="gem-points p-2" count="1" animation="progress"
                [theme]="{
              'border-radius': '5px',
              height: '40px',
              border: '1px solid white'
            }"></ngx-skeleton-loader> -->
            </ng-container>
            <ng-container *ngIf="countries.length != 0">
              <div (click)="changeCountry(country)"
                class="gem-points country-hover p-2" *ngFor="let country of countries">
                <a href="javascript:void(0)"> <img style="height: 30px;width: 30px;margin-right: 5px;"
                    [src]="country.flagImage" class="rounded-circle" alt="userimg">
                  <span class="span-color"
                    [ngStyle]="{'font-weight': countryName == country.name ? 'bold' : '' }">{{country.name}}</span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Languages' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div [ngClass]="{'selected-country' : lang.key == current_lang}" (click)="changeLang(lang.key)"
              *ngFor="let lang of langs | keyvalue" class="gem-points country-hover p-2">
              <a href="javascript:void(0)"> <img style="height: 30px;width: 30px;"
                  [src]="lang.key == 'ar' ? 'https://rahaldelivery.blob.core.windows.net/static/kuwait.png' : 'https://th.bing.com/th/id/R.235fc65463d0c1eefa102801ba282e71?rik=rToZIjguCeXCXQ&pid=ImgRaw&r=0'"
                  class="rounded-circle" alt="userimg">
                <span class="span-color">{{lang.value}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="myFavoriteModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-left">{{'Favorites' | translate}}</h4>
        <button type="button" class="close text-right" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <div class="ms-panel-body">
          <ul ngbNav #navtwo="ngbNav" class="nav nav-tabs tabs-bordered d-flex nav-justified mb-4"
            (navChange)="navChanged($event)">
            <li [ngbNavItem]="1">
              <a ngbNavLink>{{'Companies' | translate}}</a>
              <ng-template ngbNavContent>
                <div class="d-flex flex-column align-items-center pt-4" *ngIf="myFavoritesCompanies.length == 0">
                  <p><img src="assets/img/svg/013-heart-1.svg" height="40" width="40" alt=""></p>
                  <p class="font-weight-bold">{{'Fill Your Bag' | translate}}</p>
                  <p>{{'You currently don\'t have any favourite' | translate}} 🙁</p>
                </div>
                <!-- <ng-container *ngIf="myFavoritesCompanies?.length == 0">
                  <div class="card-style-height mb-2" *ngFor="let _ of [1,2]">
                    <ng-container>
                      <ngx-skeleton-loader animation="progress" ></ngx-skeleton-loader>
                    </ng-container>
                  </div>
                </ng-container> -->
                <div class="card-style mb-2 cursor" *ngFor="let fav of myFavoritesCompanies">
                  <a (click)="hideFavouriteModal(fav.id,fav.isFavorite)">
                    <!--(click)="goToCompanies()"-->
                    <div class="fav-img">
                      <h1 class="d-flex">
                        <img height="85" [src]="validURL(fav.logo) ? fav.logo : 'assets/img/banner/banner-2.jpg'"
                          alt="..." />
                        <div class="d-flex flex-column p-2">
                          <span class="font-24">{{fav.name}} </span>
                          <p><i class="fa fa-star"></i>{{fav.rate}} ({{fav.reviewsCount}})
                            <span>{{fav.description}}</span></p>
                          <p><span class="fa fa-map-marker"></span> {{fav.address}}</p>
                        </div>
                        <span class="d-flex gap-3" [ngClass]="current_lang == 'en' ? 'ml-auto mr-1' : 'mr-auto ml-1'">
                          <div>
                            <a (click)="toggleFavorite(fav.id,0)" class="pl-1 icon-font icon-favorite" href="javascript:void(0)">
                              <i class="fa fa-heart"></i>
                            </a>
                          </div>
                        </span>
                      </h1>
                    </div>
                  </a>
                </div>
                <!-- <div class="card-style mb-2">
                  <a href="/companies">
                    <div class="container fav-img">
                      <h1 class="d-flex justify-content-between">
                        <img height="85"
                          src="https://static.takeaway.com/images/restaurants/de/QPR7QNRN/logo_465x320.png?timestamp=1689960649"
                          alt="..." />
                        <div class="d-flex flex-column p-2">
                          <span class="font-24">Diazo </span>
                          <p><i class="fa fa-star"></i>4.6 (51)ad asdqwe asdweqe asdqwe ad asdqwe asdweqe asdqwe</p>
                          <p>a asd adqwe asdqwe</p>
                        </div>
                        <span class="d-flex gap-3">
                          <div (click)="toggleFavorite(1)">
                            <a class="pl-1 icon-font icon-favorite" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                          </div>
                        </span>
                      </h1>
                    </div>
                  </a>
                </div>
                <div class="card-style mb-2">
                  <a href="/companies">
                    <div class="container fav-img">
                      <h1 class="d-flex justify-content-between">
                        <img height="85"
                          src="https://static.takeaway.com/images/restaurants/de/QPR7QNRN/logo_465x320.png?timestamp=1689960649"
                          alt="..." />
                        <div class="d-flex flex-column p-2">
                          <span class="font-24">Diazo </span>
                          <p><i class="fa fa-star"></i>4.6 (51)ad asdqwe asdweqe asdqwe ad asdqwe asdweqe asdqwe</p>
                          <p>a asd adqwe asdqwe</p>
                        </div>
                        <span class="d-flex gap-3">
                          <div (click)="toggleFavorite(1)">
                            <a class="pl-1 icon-font icon-favorite" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                          </div>
                        </span>
                      </h1>
                    </div>
                  </a>
                </div> -->
              </ng-template>
            </li>
            <!-- <li [ngbNavItem]="2">
              <a ngbNavLink>{{'Products' | translate}} </a>
              <ng-template ngbNavContent>
                <div class="d-flex flex-column align-items-center pt-4" *ngIf="myFavoritesProducts.length == 0">
                  <p><img src="assets/img/svg/013-heart-1.svg" height="40" width="40" alt=""></p>
                  <p class="font-weight-bold">{{'Fill Your Bag' | translate}}</p>
                  <p>{{'You currently don\'t have any favourite' | translate}} 🙁</p>
                </div>
                <div class="card-style mb-2 cursor" *ngFor="let fav of myFavoritesProducts">
                  <a (click)="goToCompanies()">
                    <div class="fav-img">
                      <h1 class="d-flex justify-content-between">
                        <img height="85" [src]="validURL(fav.logo) ? fav.logo : 'assets/img/banner/banner-2.jpg'"
                          alt="..." />
                        <div class="d-flex flex-column p-2">
                          <span class="font-24">{{fav.name}} </span>
                          <p><i class="fa fa-star"></i>{{fav.rate}} ({{fav.reviewsCount}})<span>
                              {{fav.description}}</span></p>
                          <p><span class="fa fa-map-marker"></span> {{fav.address}}</p>
                        </div>
                        <span class="d-flex gap-3" [ngClass]="current_lang == 'en' ? 'ml-auto mr-1' : 'mr-auto ml-1'">
                          <div (click)="toggleFavorite(fav.id,1)">
                            <a class="pl-1 icon-font icon-favorite" href="#">
                              <i class="fa fa-heart"></i>
                            </a>
                          </div>
                        </span>
                      </h1>
                    </div>
                  </a>
                </div>
              </ng-template>
            </li> -->
          </ul>
          <div [ngbNavOutlet]="navtwo"></div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div class="modal fade" id="myOrdersModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-left">Orders</h4>
        <button type="button" class="close text-right" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
      </div>
      <div class="modal-body">
        <!-- <div class="card-style mb-2">
          <a href="/companies">
            <div class="container">
              <h1 class="d-flex justify-content-between">
                <img width="56" height="85"
                  src="https://static.takeaway.com/images/restaurants/de/QPR7QNRN/logo_465x320.png?timestamp=1689960649"
                  alt="..." />
                <div class="d-flex flex-column p-2">
                  <span class="font-24">Diazo </span>
                  <p><i class="fa fa-star"></i>4.6 (51)ad asdqwe asdweqe asdqwe ad asdqwe asdweqe asdqwe</p>
                  <p>a asd adqwe asdqwe</p>
                </div>
                <span class="d-flex gap-3">
                  <div (click)="toggleFavorite(1)">
                    <a class="pl-1 icon-font icon-favorite" href="#">
                      <i class="fa fa-heart"></i>
                    </a>
                  </div>
                </span>
              </h1>
            </div>
          </a>
        </div> -->
        <div class="text-center">
          <span class="fa fa-shopping-bag icon-font"></span>
          <p class="icon-font font-weight-bold">No orders yet
          </p>
          <p>You have no order history available.
          </p>
          <a class="explore-companies" routerLink="/companies" [queryParams]="{id:1}">Explore Places</a>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->